.component-scheduling-calendar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.component-scheduling-calendar-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component-scheduling-calendar-inner .date-picker-bar-container {
  padding: 0.5rem 1rem;
}
.component-scheduling-calendar-inner .date-picker-bar-container .date-picker-bar-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  scroll-margin-top: 1rem;
}
.component-scheduling-calendar-inner .date-picker-bar-container .date-picker-bar-wrapper .component-date-holder {
  margin: 0.5rem;
  margin-bottom: 1.5rem;
}
.component-scheduling-calendar-inner .time-picker-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 2rem;
}
.component-scheduling-calendar-inner .time-picker-container .time-picker-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}
.component-scheduling-calendar-inner .time-picker-container .time-picker-wrapper .component-time-holder {
  margin: 0.5rem;
  margin-bottom: 1.5rem;
}
.component-scheduling-calendar-inner .buttons-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  height: auto;
  flex-grow: 1;
  width: 100%;
  align-self: flex-end;
}
.component-scheduling-calendar-inner .buttons-nav-container p {
  margin: 0;
  line-height: 1.1rem;
}/*# sourceMappingURL=SchedulingCalendar.css.map */