.gm-main {
  position: relative;
  padding-top: 2rem;
}
@media (max-width: 1200px) {
  .gm-main {
    padding-bottom: 2rem;
  }
}
.gm-main .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.gm-main .only-pc-text {
  display: none;
  font-size: 12px;
  color: rgb(150, 151, 151);
  margin-left: 0.05rem;
}
@media only screen and (min-width: 769px) {
  .gm-main .only-pc-text.pc {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .gm-main .only-pc-text {
    display: block;
  }
}
.gm-main .download-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.gm-main .download-buttons-container .button {
  margin-right: 1rem;
}
.gm-main .gm-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .gm-main .gm-image-container {
    margin-top: 2rem;
  }
}/*# sourceMappingURL=SectionMain.css.map */