@import-normalize;
@font-face {
  font-family: "Gilroy-light";
  src: local("Gilroy-light"), url(./fonts/Gilroy-Light.otf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-regular";
  src: local("Gilroy-regular"), url(./fonts/Gilroy-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-semibold";
  src: local("Gilroy-semibold"), url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-bold";
  src: local("Gilroy-bold"), url(./fonts/Gilroy-ExtraBold.otf) format("truetype");
  font-display: swap;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #494848;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 224, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(20, 151, 168);
}

section {
  padding: 8rem 0;
  position: relative;
  background-color: black;
  overflow-x: hidden;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
    2. Remove default margin
  */
* {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  scroll-behavior: smooth !important;
}

/*
    3. Allow percentage-based heights in the application
  */
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
    6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
    7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
  font: inherit;
}

/*
    8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
    9. Create a root stacking context
  */
#root,
#__next {
  isolation: isolate;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*# sourceMappingURL=index.css.map */