.component-step {
  display: block;
  position: relative;
}
.component-step.step-three .component-step-inner .choose-option-container {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.component-step.step-three .component-step-inner .choose-option-container input:checked:checked ~ .option {
  border-color: rgb(0, 224, 255);
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
}
.component-step.step-three .component-step-inner .choose-option-container input:checked:checked ~ .option .dot {
  background: #fff;
}
.component-step.step-three .component-step-inner .choose-option-container input:checked:checked ~ .option .dot::before {
  opacity: 1;
  transform: scale(1);
}
.component-step.step-three .component-step-inner .choose-option-container input:checked:checked ~ .option span {
  color: rgb(0, 0, 0);
}/*# sourceMappingURL=StepThree.css.map */