.admin-view-functions-container {
  display: block;
  position: relative;
}
.admin-view-functions-container .admin-view-functions-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.admin-view-functions-container .admin-view-functions-wrapper .pane-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
  flex: 1;
}
.admin-view-functions-container .admin-view-functions-wrapper .pane-container:nth-of-type(odd) {
  padding-right: 2rem;
}
.admin-view-functions-container .admin-view-functions-wrapper .pane-container:nth-of-type(even) {
  padding-left: 2rem;
}
.admin-view-functions-container .admin-view-functions-wrapper .pane-container .component-admin-functions-table {
  margin-bottom: 2rem;
}
.admin-view-functions-container .admin-view-functions-wrapper .pane-container .component-admin-functions-table:last-of-type {
  margin-bottom: 0;
}/*# sourceMappingURL=AdminViewFunctions.css.map */