.component-step.step-two .component-step-inner p {
  text-align: center;
}
.component-step.step-two .component-step-inner .options-brief-container {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.component-step.step-two .component-step-inner .options-brief-container p {
  text-align: left;
}
.component-step.step-two .component-step-inner .options-brief-container .option-wrapper.advanced {
  color: rgb(0, 224, 255);
}
.component-step.step-two .component-step-inner .options-brief-container .option-wrapper.base {
  color: rgb(150, 151, 151);
}
.component-step.step-two .component-step-inner .options-brief-container .option-wrapper ul {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .component-step.step-two .component-step-inner .options-brief-container {
    flex-direction: column;
  }
}
.component-step.step-two .component-simple-container {
  padding: 1rem;
}
.component-step.step-two .component-simple-container:not(:last-of-type) {
  margin-right: 1rem;
}
@media (max-width: 767px) {
  .component-step.step-two .component-simple-container:not(:last-of-type) {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}/*# sourceMappingURL=StepTwo.css.map */