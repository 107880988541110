.component-step {
  display: block;
  position: relative;
}
.component-step.step-five .component-step-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.component-step.step-five .component-step-inner .title-wrapper {
  position: relative;
}
.component-step.step-five .component-step-inner .loader {
  flex: 0.5;
  height: 100%;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.component-step.step-five .component-step-inner .description-container {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.component-step.step-five .component-step-inner .description-container p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.component-step.step-five .component-step-inner .options-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .component-step.step-five .component-step-inner .options-container {
    flex-direction: column;
  }
}
.component-step.step-five .component-step-inner .options-container .option-container {
  display: block;
  position: relative;
  height: auto;
  flex: 1;
  border-radius: 7px;
  padding: 1rem;
  background-color: rgba(19, 18, 18, 0.8);
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.component-step.step-five .component-step-inner .options-container ol li {
  margin-bottom: 1rem;
}
.component-step.step-five .component-step-inner .options-container ol li a {
  font-family: Gilroy-semibold, sans-serif;
}
.component-step.step-five .component-step-inner .options-container input {
  margin-top: 0.5rem;
  border-radius: 7px;
  background-color: transparent;
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.6) 0px 0px 1px 1px;
  color: #FFFFFF;
  font-family: Gilroy-regular, sans-serif;
  border: none;
  padding-left: 0.5rem;
}
.component-step.step-five .component-step-inner .options-container input:focus {
  outline: none;
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(88, 101, 242, 0.8) 0px 0px 2px 2px;
}
@media (min-width: 992px) {
  .component-step.step-five .component-step-inner .component-simple-container:not(:last-of-type) {
    margin-right: 1rem;
  }
}
@media (max-width: 991px) {
  .component-step.step-five .component-step-inner .component-simple-container:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=StepFive.css.map */