.section-full-profile {
    padding: 1rem 0;
    height: 100%;

    @media (min-width: 2560px) {
        padding: 10rem;
    }

    .profile-container {
        position: relative;
        width: 100%;
        border-radius: 7px;
        box-shadow: rgba(206, 210, 212, 0.5) 0px 0px 0px 0px, rgba(0, 224, 255, 0.6) 0px 0px 1px 1px;
        background-image: url('./polygon-scatter-haikei.svg');
        overflow: hidden;
        height: 45rem;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));

    }

    .profile-wrapper {
        display: flex;
        flex-direction: column;
        height: 45rem;
        width: 100%;
        overflow: hidden;
        justify-content: flex-start;
        align-items: flex-start;

    }

    .profile-main-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 35rem;

        @media(max-width: 991px) {
            flex-direction: column;
            height: 36rem;
        }

    }

    .profile-main-block {
        display: block;
        position: relative;
        height: 100%;
        width: 85%;

        @media (max-width: 991px) {
            overflow-y: scroll;
            width: 100%;
        }
    }

    .profile-side-block {
        display: block;
        position: relative;
        width: 15%;
        padding: 0;
        border-right: 1px solid #adb5bd;

        @media(max-width: 991px) {
            border-bottom: 1px solid #adb5bd;
            width: 100%;
        }
    }



    .top-banner-container {
        width: 100%;
        border-bottom: 1px solid #adb5bd;
        height: 10rem;

        @media (max-width: 991px) {
            height: 9rem;
        }

    }





}