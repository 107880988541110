.component-contact-form.contact-page {
    position: relative;
    display: block;

    & .component-contact-form-inner {

        h1 {
            font-size: 3rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: center;
            margin-bottom: 2rem;
            line-height: 3.1rem;
        }

        .form-label {
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: left;
            margin-bottom: 0.5rem;
        }

        .form-select.phone-select {
            display: flex;
            height: auto;
            background-color: rgb(0, 224, 255);
            flex: 0.2;
            justify-content: center;
        }

        .phone-input {
            flex: 0.8;
        }

        
        .button {
            margin-top: 1rem;
            font-size: 1rem;
            padding: 0.6rem 2.1rem;
            font-family: Gilroy-semibold, sans-serif;
        }

        .form-contact-group {
            margin-bottom: 0.7rem;

            &.alternative {
                .form-label {
                    font-family: Gilroy-regular, sans-serif;
                    color: rgba(0, 224, 255, 0.8);
                }

                .form-control {
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 0, 0, 1);
                }
            }
        }

        .plaintext {
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 0, 0, 1);
            background-color: rgba(255, 255, 255, 0.7);
            border: rgba(0, 0, 0, 0.7);
        }

        .form-control {
            &:disabled {
                background-color: rgba(255, 255, 255, 0.7);
            }
        }

        .choose-option-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: flex-start;

            .button {
                margin-bottom: 1rem;
            }

            &:last-of-type {
                margin-bottom: 1.3rem;
            }

            p {
                margin-bottom: 1rem;
                color: white;
            }

            .options-container {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 7px;
                flex-wrap: wrap;
                transition: all 0.3s ease;

                .option-wrapper {
                    * {
                        transition: all 0.3s ease;
                    }

                    .dot {
                        height: 20px;
                        width: 20px;
                        max-height: 20px;
                        max-width: 20px;
                        background: rgb(150, 151, 151);
                        border-radius: 4px;
                        position: relative;
                        margin-right: 0.7rem;

                        &::before {
                            position: absolute;
                            content: "";
                            top: 4px;
                            left: 4px;
                            width: 12px;
                            height: 12px;
                            background: rgb(0, 0, 0);
                            border-radius: 4px;
                            opacity: 0;
                            transform: scale(1.5);
                            transition: all 0.3s ease;
                        }
                    }

                    .option {

                        background: transparent;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 7px;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        min-width: 13rem;

                        &.preferable {

                            border: 2px solid rgb(0, 224, 255);

                            span {
                                color: rgb(0, 224, 255);
                            }
                        }

                        span {
                            font-size: 0.8rem;
                            color: rgb(150, 151, 151);

                            a {
                                text-decoration: none;
                                color: rgb(150, 151, 151);
                            }
                        }

                    }

                    input[type="radio"],
                    input[type="checkbox"] {
                        display: none;
                        transition: all 0.3s ease-in-out;
                    }

                    input:checked:checked~.option {
                        color: rgb(0, 224, 255);
                    }

                    input:checked:checked~.option .dot {
                        background: #fff;
                    }

                    input:checked:checked~.option .dot::before {
                        opacity: 1;
                        transform: scale(1);
                    }

                    input:checked:checked~.option span {
                        color: #FFFFFF;

                        a {
                            color: #FFFFFF;
                        }

                    }

                }

            }



        }
    }

}