.component-settings-function-bar {
    display: block;
    position: relative;
    height: fit-content;
    width: 100%;
    border-radius: 7px;
    box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
    background-color: rgba(19, 18, 18, 0.8);
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 1) 0px 0px 2px 2px;
        cursor: pointer;

        h1 {
            font-size: 1.2rem;
        }
    }

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0.8rem;

        h1 {
            font-family: 'Gilroy-bold', sans-serif;
            font-size: 1rem;
            line-height: 1.4rem;
            padding: 0;
            margin: 0;
            color: rgb(0, 224, 255);
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
        }
    }
}