.component-top-navigation {
    display: block;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &.absolute {
        position: absolute;
        z-index: 1005;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &.black {
        background-color: rgba(0, 0, 0, 1);
    }

    .col.center {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.end {
            justify-content: flex-end;
        }
    }

    &-inner {
        font-family: Gilroy-semibold, sans-serif;
        letter-spacing: 1px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 3rem;
        padding-bottom: 3rem;
        align-items: center;

        .list-container {

            ul {
                display: flex;
                flex-direction: row;
                align-items: center;
                list-style-type: none;
                margin: 0;
                padding: 0;
                overflow: visible;
                background-color: transparent;
                float: right;

                li {
                    display: inline-block;
                    margin-left: 1.7rem;
                    margin-right: 1.7rem;
                    font-size: 1.1rem;
                    text-align: center;


                    &.nav-highlighted {

                        -webkit-animation: glow 1s ease-in-out infinite alternate;
                        -moz-animation: glow 1s ease-in-out infinite alternate;
                        animation: glow 1s ease-in-out infinite alternate;

                    }

                    @keyframes glow {
                        from {
                            text-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                                0 0 10px rgba(255, 255, 255, 0.5),
                                0 0 15px rgba(28, 179, 206, 0.5),
                                0 0 20px rgba(35, 180, 221, 0.5),
                                0 0 25px rgba(21, 200, 232, 0.5),
                                0 0 30px rgba(6, 193, 245, 0.5),
                                0 0 35px rgba(11, 219, 247, 0.5);
                        }

                        to {
                            text-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
                                0 0 15px rgba(0, 208, 255, 0.5),
                                0 0 20px rgba(77, 234, 255, 0.5),
                                0 0 25px rgba(39, 223, 255, 0.5),
                                0 0 30px rgba(0, 208, 236, 0.5),
                                0 0 35px rgba(0, 183, 255, 0.5),
                                0 0 40px rgba(12, 194, 255, 0.5);
                        }
                    }

                    &:last-of-type {
                        display: flex;
                        align-items: center;
                        margin-right: 0;
                        margin-left: 1rem;
                    }

                    a {
                        text-decoration: none;
                        color: #FFFFFF;
                        font-family: Gilroy-semibold, sans-serif;

                        &:hover {
                            text-decoration: none;
                            color: rgba(0, 224, 255, 1);
                            transition: color 0.1s ease-in-out;
                        }

                    }

                    &.account-menu {
                        margin-right: 0rem;

                        a {
                            &:hover {
                                text-decoration: none;
                                color: rgba(0, 224, 255, 1);
                                transition: inherit;
                            }
                        }
                    }
                }

                .button {
                    font-size: 1.1rem;
                    padding: 0.5rem 1.2rem;
                    color: black;
                    letter-spacing: normal;
                    font-family: Gilroy-semibold, sans-serif;

                    &.try {
                        background-color: rgba(0, 224, 255, 1);
                        color: black;
                        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;

                        &.black {
                            background-color: rgba(0, 0, 0, 0.2);
                            color: rgba(0, 224, 255, 1);
                            border: 1px solid rgba(0, 224, 255, 0.5);

                            &:hover {
                                border: 1px solid rgba(198, 196, 196, 1);
                                color: rgb(255, 255, 255);
                            }
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.2);
                            color: rgba(0, 224, 255, 1);
                            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
                            border: 1px solid rgba(0, 224, 255, 1);
                        }
                    }
                }
            }

            .dropdown-menu-container {
                position: absolute;
                margin: -1.7rem 0 0 -1.5rem;
                height: 120px;
                width: 190px;
                // height: 100px;
                // width: 100px;
            }

            .nav-dropdown {
                border: white;

                @keyframes slideDown {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

                .nav-link {
                    position: relative;
                    z-index: 1002;
                }

                .dropdown-menu {
                    animation: slideDown 0.2s ease-in-out;
                    margin-top: 0.2rem;

                    &.show {
                        background-color: rgb(19, 19, 19);
                        border: 1px solid #FFFFFF;
                        border-radius: 7px;
                    }

                    .dropdown-item {
                        color: #FFFFFF;
                        font-family: Gilroy-semibold, sans-serif;
                        font-size: 0.9rem;

                        &:hover {
                            background-color: rgba(0, 224, 255, 1);
                            color: black;
                        }
                    }
                }
            }

            @media (max-width: 991px) {
                display: none;
                height: 0px;
            }

            @media (min-width: 992px) and (max-width: 1199px) {

                ul {
                    li {
                        a {
                            font-size: 1.05rem;
                        }

                        .button {
                            font-size: 1.05rem;
                        }

                        font-size: 1.05rem;
                        margin-left: 1.1rem;
                        margin-right: 1.1rem;
                    }
                }

            }
        }

        .logotype-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80%;
            width: 10rem;

            a {
                width: 100%;
                height: auto;
            }
        }

        @media (max-width: 575px) {

            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
        }

        @media (min-width: 576px) and (max-width:991px) {

            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}