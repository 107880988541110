.component-review {
  display: block;
  position: relative;
  height: 100%;
  border: 1px solid rgb(0, 224, 255);
  border-radius: 7px;
  background-color: rgba(19, 19, 19, 0.8);
  height: 30rem;
  margin: 1rem;
}
.component-review.long-name .component-review-inner .review-info-container {
  margin-right: -1rem;
}
.component-review-inner {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  flex: 1;
  flex-grow: 1;
}
.component-review-inner .review-personal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  height: 7rem;
  align-self: flex-start;
}
.component-review-inner .review-personal-container .review-avatar-wrapper {
  border: 1px solid rgba(131, 127, 127, 0.2);
  box-shadow: rgba(131, 127, 127, 0.5) 0px 0px 3px 1px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.component-review-inner .review-personal-container .review-avatar-wrapper img {
  width: auto;
  height: 100%;
}
.component-review-inner .review-personal-container .review-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component-review-inner .review-personal-container .review-info-container .review-name-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}
.component-review-inner .review-personal-container .review-info-container .review-name-wrapper h4 {
  font-family: Gilroy-bold, sans-serif;
  font-size: 2rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin: 0;
}
.component-review-inner .review-personal-container .review-info-container .review-career-wrapper {
  width: 100%;
}
.component-review-inner .review-personal-container .review-info-container .review-career-wrapper h5 {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  background: linear-gradient(25deg, rgb(131, 127, 127) 67%, rgb(73, 72, 72) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.component-review-inner .review-text-container {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  font-family: Gilroy-regular, sans-serif;
  color: rgb(131, 127, 127);
  font-size: 1rem;
  height: 15rem;
}
.component-review-inner .review-stars-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}/*# sourceMappingURL=Review.css.map */