.component-fragment-main {

  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1.7rem;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 991px) {
    padding: 1rem;
    overflow-y: hidden;
    height: auto;
  }


  &-inner {
    display: flex;
    flex-direction: column;
    height: auto;

  }
}