.view-svc-scheduling-container {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    min-height: 31rem;
    font-family: Gilroy-semibold, sans-serif;

    .view-svc-scheduling-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 31rem;
        width: 100%;

        .component-prepare-step {
            margin-bottom: 0;
            padding-top: 1rem;
        }

        .component-simple-container {
            padding: 1rem 0;
        }

        .spinner-container {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            color: #FFFFFF;
            font-size: 1rem;
            line-height: 1.7rem;
        }

        h4 {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 2rem;
            line-height: 2.2rem;
        }

        h5 {
            margin-top: 1rem;
            background: linear-gradient(25deg, rgba(198, 196, 196, 1) 70%, rgba(255, 255, 255, 1) 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 1rem;
            line-height: 1.2rem;
            margin: 0;
        }

        .header-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: 0.2;
            text-align: center;
            width: 100%;

            .counter-wrapper {
                width: 100%;

                .alert {
                    padding: 0.5rem;
                    margin-top: 1rem;
                }


            }
        }

        .step-view-container {
            flex: 7;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 1rem;
            background-color: rgba(19, 18, 18, 0.8);
            box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
            border-radius: 7px;

            &.center {
                align-items: center;
            }

            .step-view-text-container {
                text-align: center;
            }
        }

        .bottom-nav-container {
            display: flex;
            flex-direction: row;
            flex: 0.5;
            justify-content: flex-end;
            align-items: flex-end;
            flex-wrap: wrap;

            .bottom-nav-left {
                flex: 1;
                text-align: left;
                margin-right: 1rem;
            }

            .bottom-nav-right {
                flex: 1;
                text-align: right;
                margin-left: 1rem;
            }
        }

        .choose-option-container {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;

            p {
                margin-right: 1rem;
                color: white;
                margin-bottom: 1rem;
                filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
            }

            .options-container {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 5px;
                flex-wrap: wrap;

                .option-wrapper {

                    .option {
                        background: rgba(19, 18, 18, 0.8);
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 7px;
                        cursor: pointer;
                        padding: 0.8rem 1.7rem;
                        border: 2px solid rgb(150, 151, 151);
                        transition: all 0.3s ease;
                        margin-bottom: 1rem;

                        &.preferable {

                            border: 2px solid rgb(0, 224, 255);

                            span {
                                color: rgb(0, 224, 255);
                            }
                        }

                        .dot {
                            height: 20px;
                            width: 20px;
                            max-height: 20px;
                            max-width: 20px;
                            background: #d9d9d9;
                            border-radius: 50%;
                            position: relative;
                            margin-right: 1rem;

                            &::before {
                                position: absolute;
                                content: "";
                                top: 4px;
                                left: 4px;
                                width: 12px;
                                height: 12px;
                                background: rgb(0, 0, 0);
                                border-radius: 50%;
                                opacity: 0;
                                transform: scale(1.5);
                                transition: all 0.3s ease;
                            }
                        }

                        span {
                            font-size: 1rem;
                            color: rgb(150, 151, 151);
                        }
                    }

                    input[type="radio"] {
                        display: none;
                    }

                    input:checked:checked~.option {
                        border-color: rgb(0, 224, 255);
                        background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                    }

                    input:checked:checked~.option .dot {
                        background: #fff;
                    }

                    input:checked:checked~.option .dot::before {
                        opacity: 1;
                        transform: scale(1);
                    }

                    input:checked:checked~.option span {
                        color: rgb(0, 0, 0)
                    }

                    &:not(:last-of-type) {
                        margin-right: 1rem;
                    }
                }

            }



        }
    }
}