.component-text {
  display: block;
  position: relative;
  font-family: Gilroy-bold, sans-serif;
  margin-bottom: 2rem;
  filter: drop-shadow(12px 12px 16px rgba(0, 0, 0, 0.7));
}
.component-text.center .component-text-inner {
  justify-content: center;
  align-items: center;
}
.component-text.center .component-text-inner h1,
.component-text.center .component-text-inner h2,
.component-text.center .component-text-inner h3,
.component-text.center .component-text-inner h4,
.component-text.center .component-text-inner p {
  text-align: center;
}
.component-text.left .component-text-inner {
  justify-content: left;
  align-items: flex-start;
}
.component-text.left .component-text-inner h1,
.component-text.left .component-text-inner h2,
.component-text.left .component-text-inner h3,
.component-text.left .component-text-inner h4,
.component-text.left .component-text-inner p {
  text-align: left;
}
.component-text.right .component-text-inner {
  justify-content: right;
  align-items: flex-end;
}
.component-text.right .component-text-inner h1,
.component-text.right .component-text-inner h2,
.component-text.right .component-text-inner h3,
.component-text.right .component-text-inner h4,
.component-text.right .component-text-inner p {
  text-align: right;
}
.component-text.subpage {
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.component-text.subpage .component-text-inner h1 {
  font-size: 3rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: #00e0ff;
}
.component-text.subpage .component-text-inner h2 {
  font-size: 1rem;
  background: linear-gradient(25deg, rgba(255, 253, 253, 0.2) 70%, rgba(255, 255, 255, 0.2) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (max-width: 575px) {
  .component-text.subpage .component-text-inner h1 {
    font-size: 1.666rem;
  }
  .component-text.subpage .component-text-inner h2 {
    font-size: 0.7rem;
  }
  .component-text.subpage .component-text-inner h3 {
    font-size: 1.96rem;
  }
  .component-text.subpage .component-text-inner h4 {
    font-size: 1.4rem;
  }
  .component-text.subpage .component-text-inner h5 {
    font-size: 1.12rem;
  }
  .component-text.subpage .component-text-inner p {
    font-size: 0.98rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .component-text.subpage .component-text-inner h1 {
    font-size: 1.904rem;
  }
  .component-text.subpage .component-text-inner h2 {
    font-size: 0.8rem;
  }
  .component-text.subpage .component-text-inner h3 {
    font-size: 2.24rem;
  }
  .component-text.subpage .component-text-inner h4 {
    font-size: 1.6rem;
  }
  .component-text.subpage .component-text-inner h5 {
    font-size: 1.28rem;
  }
  .component-text.subpage .component-text-inner p {
    font-size: 1.12rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .component-text.subpage .component-text-inner h1 {
    font-size: 2.142rem;
  }
  .component-text.subpage .component-text-inner h2 {
    font-size: 0.9rem;
  }
  .component-text.subpage .component-text-inner h3 {
    font-size: 2.52rem;
  }
  .component-text.subpage .component-text-inner h4 {
    font-size: 1.8rem;
  }
  .component-text.subpage .component-text-inner h5 {
    font-size: 1.44rem;
  }
  .component-text.subpage .component-text-inner p {
    font-size: 1.26rem;
  }
}
.component-text.subpage p {
  margin-top: 2rem;
}
.component-text.subpage.white .component-text-inner h2 {
  font-size: 1rem;
  background: #ffffff;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (max-width: 767px) {
  .component-text.subpage {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
}
.component-text.small {
  margin-top: 4rem;
  padding-top: 2rem;
}
.component-text.small p {
  font-size: 1.1rem;
}
.component-text.small:first-of-type {
  margin-top: 4rem;
  padding-top: 2rem;
}
@media (max-width: 767px) {
  .component-text.small:first-of-type {
    margin-top: 2rem;
    padding-top: 1rem;
  }
}
@media (max-width: 767px) {
  .component-text.small {
    margin-top: 2rem;
    padding-top: 2rem;
  }
}
.component-text-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.component-text-inner h1 {
  font-size: 3.4rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.component-text-inner h2 {
  font-size: 2.5rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.component-text-inner h3 {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 2.8rem;
  font-family: Gilroy-bold, sans-serif;
}
.component-text-inner h4 {
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}
.component-text-inner h5 {
  background: rgb(0, 224, 255);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}
.component-text-inner p {
  background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgb(255, 255, 255) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1.4rem;
  line-height: 1.5rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
}
@media (max-width: 575px) {
  .component-text-inner h1 {
    font-size: 1.666rem;
  }
  .component-text-inner h2 {
    font-size: 1.75rem;
  }
  .component-text-inner h3 {
    font-size: 1.96rem;
  }
  .component-text-inner h4 {
    font-size: 1.4rem;
  }
  .component-text-inner h5 {
    font-size: 1.12rem;
  }
  .component-text-inner p {
    font-size: 0.98rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .component-text-inner h1 {
    font-size: 1.904rem;
  }
  .component-text-inner h2 {
    font-size: 2rem;
  }
  .component-text-inner h3 {
    font-size: 2.24rem;
  }
  .component-text-inner h4 {
    font-size: 1.6rem;
  }
  .component-text-inner h5 {
    font-size: 1.28rem;
  }
  .component-text-inner p {
    font-size: 1.12rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .component-text-inner h1 {
    font-size: 2.142rem;
  }
  .component-text-inner h2 {
    font-size: 2.25rem;
  }
  .component-text-inner h3 {
    font-size: 2.52rem;
  }
  .component-text-inner h4 {
    font-size: 1.8rem;
  }
  .component-text-inner h5 {
    font-size: 1.44rem;
  }
  .component-text-inner p {
    font-size: 1.26rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .component-text-inner h1 {
    font-size: 2.142rem;
  }
  .component-text-inner h2 {
    font-size: 2.25rem;
  }
  .component-text-inner h3 {
    font-size: 2.52rem;
  }
  .component-text-inner h4 {
    font-size: 1.8rem;
  }
  .component-text-inner h5 {
    font-size: 1.44rem;
  }
  .component-text-inner p {
    font-size: 1.26rem;
  }
}/*# sourceMappingURL=Text.css.map */