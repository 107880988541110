.view-prepare-container {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  min-height: 31rem;
  font-family: Gilroy-semibold, sans-serif;
}
.view-prepare-container .view-prepare-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 31rem;
  width: 100%;
}
.view-prepare-container .view-prepare-wrapper .spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-prepare-container .view-prepare-wrapper p {
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1.7rem;
}
.view-prepare-container .view-prepare-wrapper h4 {
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 2rem;
  line-height: 2.2rem;
}
.view-prepare-container .view-prepare-wrapper h5 {
  margin-top: 1rem;
  background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgb(255, 255, 255) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0;
}
.view-prepare-container .view-prepare-wrapper .header-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 0.2;
  text-align: center;
  width: 100%;
}
.view-prepare-container .view-prepare-wrapper .header-container .counter-wrapper {
  width: 100%;
}
.view-prepare-container .view-prepare-wrapper .header-container .counter-wrapper .alert {
  padding: 0.5rem;
  margin-top: 1rem;
}
.view-prepare-container .view-prepare-wrapper .step-view-container {
  flex: 7;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1rem;
}
.view-prepare-container .view-prepare-wrapper .step-view-container.center {
  align-items: center;
}
.view-prepare-container .view-prepare-wrapper .step-view-container .step-view-text-container {
  text-align: center;
}
.view-prepare-container .view-prepare-wrapper .bottom-nav-container {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.view-prepare-container .view-prepare-wrapper .bottom-nav-container .bottom-nav-left {
  flex: 1;
  text-align: left;
  margin-right: 1rem;
}
.view-prepare-container .view-prepare-wrapper .bottom-nav-container .bottom-nav-right {
  flex: 1;
  text-align: right;
  margin-left: 1rem;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container p {
  margin-right: 1rem;
  color: white;
  margin-bottom: 1rem;
  filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  flex-wrap: wrap;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option {
  background: rgba(19, 18, 18, 0.8);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 0.8rem 1.7rem;
  border: 2px solid rgb(150, 151, 151);
  transition: all 0.3s ease;
  margin-bottom: 1rem;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option.preferable {
  border: 2px solid rgb(0, 224, 255);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option.preferable span {
  color: rgb(0, 224, 255);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option .dot {
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  background: rgb(150, 151, 151);
  border-radius: 4px;
  position: relative;
  margin-right: 0.7rem;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: rgb(0, 0, 0);
  border-radius: 4px;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper .option span {
  font-size: 1rem;
  color: rgb(150, 151, 151);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper input[type=radio] {
  display: none;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option {
  border-color: rgb(0, 224, 255);
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot {
  background: #fff;
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot::before {
  opacity: 1;
  transform: scale(1);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option span {
  color: rgb(0, 0, 0);
}
.view-prepare-container .view-prepare-wrapper .choose-option-container .options-container .option-wrapper:not(:last-of-type) {
  margin-right: 1rem;
}/*# sourceMappingURL=ViewPrepare.css.map */