@import "./index.css";

/* Loading spinner edit */

.popover {
  border: 1px solid #00e0ff;

  .popover-header {
    font-size: 1rem;
    color: #00e0ff;
    background-color: rgba(19, 19, 19, 0.95);
    border: 1px solid rgb(0, 224, 255);
    border-bottom: 0;
    border-radius: 7px 7px 0 0;
  }

  .popover-body {
    background-color: rgba(19, 19, 19, 0.95);
    border: 1px solid #00e0ff;
    border-top: 0;
    color: #FFF;
    border-radius: 0 0 7px 7px;
  }
}

header {
  display: block;
  position: relative;
  background-color: rgba(3, 13, 20, .6);
}

.spinner-border.loading-spinner {
  color: rgba(0, 224, 255, 1) !important;
  --bs-spinner-width: 8rem;
  --bs-spinner-height: 8rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.8em;
  --bs-spinner-animation-speed: 1s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid rgb(0, 224, 255);
  border-right-color: transparent;

  &.small {
    --bs-spinner-width: 8rem;
    --bs-spinner-height: 8rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.8em;
  }

  &.black {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-vertical-align: -0.250em;
    color: rgba(0, 224, 255, 1) !important;
    border: var(--bs-spinner-border-width) solid rgb(0, 0, 0);
    border-right-color: transparent;
  }
}

.modal-content {
  .modal-header {
    font-family: Gilroy-semibold, sans-serif;
  }

  .modal-body {
    font-family: Gilroy-regular, sans-serif;
  }

}

a,
p {
  text-underline-offset: 5px;
}



.form-control {
  font-family: 'Gilroy-regular', sans-serif;
}

.form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgba(0, 224, 255, 1);
  text-align: left;
  margin-bottom: 0.5rem;
}

.form.modal-form {
  .form-label {
    font-size: 1rem;
    font-family: Gilroy-semibold, sans-serif;
    color: rgb(0, 0, 0);
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}


#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  // background-color: black;
  font-family: 'Gilroy-semibold', sans-serif;
  background-image: url('./assets/img/polygon-scatter-haikei.svg');
  // display: flex;
  // flex-direction: column;
}


// main {
//   flex-grow: 1;
// }

section {
  padding: 8rem 0;
  position: relative;
  // background-color: black;
  overflow-x: hidden;

  @media (max-width: 991px) {
    padding: 5.3rem 0;
  }

  @media (min-width: 992px) and (max-width: 1919px) {
    padding: 6.5rem 0;
  }
}


.page {
  position: relative;
  display: block;
  // background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(3, 13, 20, 0.6);

  &.homepage {
    background-color: rgba(3, 13, 20, 0.6);
    background-image: none;
  }

}

section {
  background-color: transparent;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}