.settings-button {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  width: 2.3rem;
}
.settings-button img {
  max-width: 100%;
}
.settings-button.btn.btn-primary {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  width: 2.3rem;
}
.settings-button.btn.btn-primary:hover {
  background-color: transparent;
}
.settings-button.btn.btn-primary:focus {
  background-color: transparent;
}
.settings-button.btn.btn-primary:active {
  background-color: transparent;
}
.settings-button:hover {
  background-color: transparent;
  filter: invert(49%) sepia(71%) saturate(3535%) hue-rotate(163deg) brightness(97%) contrast(106%);
}
.settings-button:focus {
  background-color: transparent;
}
.settings-button:active {
  background-color: transparent;
}/*# sourceMappingURL=SettingsButton.css.map */