.component-order-step.service-details {
  border: 1px solid rgba(0, 224, 255, 0.35);
}
.component-order-step.service-details .component-order-step-inner h3 {
  margin-bottom: 4rem;
}
.component-order-step.service-details .component-order-step-inner .service-details-container {
  flex: 1;
  height: 100%;
  width: 100%;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container {
  display: block;
  position: relative;
  margin-bottom: 4rem;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper p {
  margin: 0;
  color: rgb(0, 224, 255);
  text-align: center;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper h1 {
  font-size: 2rem;
  color: #FFF;
  line-height: 2.8rem;
  text-align: center;
  flex-grow: 1;
  margin: 0;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper h1 span {
  font-size: 1.2rem;
  line-height: 1.3rem;
  margin-left: 0.2rem;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper .flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.2rem;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper .flex-wrapper:last-of-type {
  margin: 0;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper .flex-wrapper:nth-of-type(odd) p {
  color: #FFF;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-name-price-container .product-name-price-wrapper .flex-wrapper:nth-of-type(odd) h1 {
  color: rgb(0, 224, 255);
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .sponsor-slider-wrapper {
  height: auto;
  display: block;
  overflow: hidden;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .sponsor-slider-wrapper p {
  color: rgb(150, 151, 151);
  text-align: center;
  line-height: 1.4rem;
  font-family: Gilroy-regular, sans-serif;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-price-container p {
  font-size: 2.5rem;
  color: #FFF;
  text-align: center;
  font-family: Gilroy-bold, sans-serif;
  line-height: 2.8rem;
}
.component-order-step.service-details .component-order-step-inner .service-details-container .service-details-wrapper .product-photo-wrapper {
  width: 3rem;
  height: 3rem;
  text-align: center;
  align-self: center;
}
@media (max-width: 991px) {
  .component-order-step.service-details .component-order-step-inner {
    flex-direction: column;
  }
  .component-order-step.service-details .component-order-step-inner .payment-info-container {
    margin-bottom: 2rem;
  }
  .component-order-step.service-details .component-order-step-inner .payment-info-container .payment-info-wrapper {
    display: flex;
    flex-direction: row;
  }
}
.component-order-step.payment .component-order-step-inner .component-product-payment {
  display: block;
  position: relative;
  height: 100%;
}
.component-order-step.payment .component-order-step-inner .component-product-payment-inner {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}
.component-order-step.payment .component-order-step-inner .component-product-payment-inner .payment-form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}/*# sourceMappingURL=OrderStepThree.css.map */