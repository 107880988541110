.signup {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 2048px) and (max-width: 3839px) {
  .signup {
    padding: 8rem 4rem;
  }
}
@media (min-width: 3840px) {
  .signup {
    padding: 8rem 4rem;
  }
}
.signup .section-signup-form {
  padding: 0;
}/*# sourceMappingURL=Signup.css.map */