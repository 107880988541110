.admin-view-mail-invite-container {
    display: block;
    position: relative;
    height: 100%;
    float: inline-end;

    .admin-view-mail-invite-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: normal;
        height: 100%;

        .button {
            width: 100%;
        }
    }
}