.gm-how-it-works {
  padding-top: 0;
}

.gm-image-container {
  display: none;
}
@media (min-width: 1200px) {
  .gm-image-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}/*# sourceMappingURL=SectionHowItWorks.css.map */