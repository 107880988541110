.component-calendar {
    display: block;
    position: relative;
    width: 100%;
    min-height: 12rem;
    // border: 1px solid rgb(0, 224, 255);

    &-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .date-picker-bar-container {

            padding: 0.5rem 1rem;


            .date-picker-bar-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                overflow-x: scroll;
                scroll-margin-top: 1rem;

                .component-date-holder {
                    margin: 0.5rem;
                    margin-bottom: 1.5rem;
                }
            }
        }

        .time-picker-container {
            display: flex;
            justify-content: center;
            padding: 1rem;

            .time-picker-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                overflow-x: scroll;

                .component-time-holder {
                    margin: 0.5rem;
                    margin-bottom: 1.5rem;
                }
            }
        }

        .button-back-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;
            height: auto;
            flex-grow: 1;
            width: 100%;

            .button {
                margin-right: 1rem;
            }

            p {
                margin: 0;
                line-height: 1.1rem;
            }
        }
    }
}