.component-fragment-main {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1.7rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (max-width: 991px) {
  .component-fragment-main {
    padding: 1rem;
    overflow-y: hidden;
    height: auto;
  }
}
.component-fragment-main-inner {
  display: flex;
  flex-direction: column;
  height: auto;
}/*# sourceMappingURL=FragmentMain.css.map */