.component-step {
    display: block;
    position: relative;

    &.step-three {
        .component-step-inner {
            .choose-option-container {
                margin-top: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                input:checked:checked~.option {
                    border-color: rgb(0, 224, 255);
                    background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                }

                input:checked:checked~.option .dot {
                    background: #fff;
                }

                input:checked:checked~.option .dot::before {
                    opacity: 1;
                    transform: scale(1);
                }

                input:checked:checked~.option span {
                    color: rgb(0, 0, 0)
                }
            }



        }
    }
}