.section-stars-and-teams {
    position: relative;

    .row {
        .hiding-image-wrapper {
            margin-top: 2rem;

            @media (min-width: 576px) and (max-width: 767px) {
                &:first-of-type {}

                &:nth-of-type(2) {}
            }

            @media (min-width: 768px) and (max-width: 991px) {

                &:nth-of-type(2) {
                    margin-top: 3rem;
                }
            }

            @media (min-width: 992px) and (max-width: 1199px) {}

            @media (min-width: 1200px) and (max-width: 1399px) {}


        }
    }

    .stars-container,
    .teams-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;

        .component-hiding-image {
            height: 30rem;

            @media (max-width: 575px) {
                height: 19rem;
            }

            @media (min-width: 576px) and (max-width: 767px) {

                height: 24rem;
            }

            @media (min-width: 768px) and (max-width: 991px) {
                height: 31rem;
            }

            @media (min-width: 992px) and (max-width: 1199px) {

                height: 23rem;
            }

            @media (min-width: 1200px) and (max-width: 1399px) {
                height: 26rem;
            }
        }

        #stars-headline {
            @media (max-width: 991px) {
                margin-top: -3rem;
            }
        }

        .headline-wrapper {
            height: 3rem;
            filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));

            h3 {
                font-family: Gilroy-bold, sans-serif;
                font-size: 2.5rem;
                background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
                text-align: center;


                @media (max-width: 575px) {
                    font-size: 1.8rem;
                }

                @media (min-width: 576px) and (max-width: 767px) {
                    font-size: 1.8rem;
                }

                @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 2rem;
                }

                @media (min-width: 992px) and (max-width: 1199px) {}

                @media (min-width: 1200px) {}
            }
        }
    }

}