.section-pw-reset-form {
  padding: 0;
}
.section-pw-reset-form .form-container {
  border: 1px solid rgb(0, 224, 255);
  border-radius: 0.6rem;
  padding: 5rem;
  padding-bottom: 3rem;
  min-height: 35rem;
  width: 100%;
  background-color: rgba(19, 18, 18, 0.8);
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
}
.section-pw-reset-form .form-container .form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.section-pw-reset-form .form-container .form-wrapper .turnstile-container {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.section-pw-reset-form .form-container .form-wrapper .turnstile-container iframe {
  border-radius: 7px !important;
  border: 1px solid rgb(0, 224, 255) !important;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form {
  width: 100%;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form h1 {
  font-size: 3rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3.1rem;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form .input-group .password-toggle {
  display: flex;
  height: auto;
  background-color: rgb(0, 224, 255);
  flex: 0.05;
  justify-content: center;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form .input-group .password-toggle img {
  max-width: 100%;
  width: 1.5rem;
  margin: 0;
  padding: 0;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form .button {
  font-size: 1.1rem;
}
.section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form .form-group {
  margin-bottom: 1.5rem;
}
@media (max-width: 991px) {
  .section-pw-reset-form .form-container {
    padding: 1rem;
  }
  .section-pw-reset-form .form-container .form-wrapper .form.pw-reset-form h1 {
    font-size: 2.1rem;
  }
}
.section-pw-reset-form .have-account-text-wrapper {
  margin-top: 0.3rem;
  width: 100%;
  text-align: center;
}
.section-pw-reset-form .have-account-text-wrapper p {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
}
.section-pw-reset-form .have-account-text-wrapper a {
  text-decoration: none;
}
.section-pw-reset-form .have-account-text-wrapper a .highlighted {
  color: rgb(0, 224, 255);
  font-weight: bold;
}

.spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}/*# sourceMappingURL=PasswordResetForm.css.map */