.gm-steam-boost {
  margin-top: 0;
  padding-top: 0;
}

.gm-steam-img-container {
  display: none;
}
@media (min-width: 992px) {
  .gm-steam-img-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}/*# sourceMappingURL=SectionSteamBoost.css.map */