.admin-views-holder-container {
    display: block;
    position: relative;
    height: 100%;

    .admin-views-holder-wrapper {
        height: 100%;
        width: 100%;
        height: auto;

        @media (max-width: 991px) {
            height: auto;
        }
    }
}