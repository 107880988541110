.component-shop-product {
    display: block;
    position: relative;
    width: 100%;
    background-color: rgba(19, 19, 19, 1);
    border: 1px solid rgba(32, 169, 187, 0.7);
    border-radius: 1rem;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    padding-top: 1rem;
    resize: none;
    max-width: 100%;

    &.nohover {
        &:hover {
            border: 1px solid rgba(0, 224, 255, 1);
            transition: none;
            transform: none;
            z-index: 2;
        }
    }

    &.disabled {
        opacity: 0.5;
        background-color: rgba(50, 50, 50, 1);
        cursor: not-allowed;

        &:hover {
            transform: none;
            border: 1px solid rgba(32, 169, 187, 0.5);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            text-decoration: line-through;
            color: rgba(150, 150, 150, 1);
        }

        * {
            pointer-events: none;
            opacity: 0.7;
        }

        &:hover {
            border: 1px solid rgba(0, 224, 255, 1);
            transition: none;
            transform: none;
            z-index: 2;
        }
    }

    &:hover {

        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        z-index: 2;

        .product-name-container {
            h4 {
                color: rgba(0, 224, 255, 1);
                transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
            }

        }

        .product-price-container {


            h3 {
                color: rgba(0, 224, 255, 1);
                transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
            }

        }

    }

    &.bestseller {
        border: 1px solid #00e0ff;
        box-shadow: #00e0ff 0px 1px 0px 4px;

        &:hover {
            border: 1px solid rgba(0, 224, 255, 1);
        }

        &:before {
            content: 'Bestseller! 🔥💻';
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 1rem;
            line-height: 1.1rem;
            text-align: center;
            transform-origin: top right;
            background-color: #00e0ff;
            color: #000000;
            padding: 0.2rem 0.5rem;
            font-weight: bold;
            border-radius: 0 7px 0 7px;
            box-shadow: rgba(0, 224, 255, 1) 0px 1px 0px 1px;
        }

        &.special {

            &:before {
                content: 'Merry Christmas 🎅🎄';
                background-color: #00e0ff;
                padding: 0.3rem 1rem;
                font-size: 1.1rem;
                font-family: Gilroy-Bold, sans-serif;
            }
        }

        &.promo {
            &:before {
                // content: '🎁🐗';
                content: 'HOOOLYYYY! 🐗';
                position: absolute;
                top: 0px;
                right: 0px;
                font-size: 1rem;
                line-height: 1.1rem;
                text-align: center;
                transform-origin: top right;
                background-color: #00e0ff;
                color: #000000;
                padding: 0.2rem 0.5rem;
                font-weight: bold;
                border-radius: 0 7px 0 7px;
                box-shadow: rgba(0, 224, 255, 1) 0px 1px 0px 1px;
                transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
            }

            &:hover {
                &:before {
                    background-color: rgb(17, 247, 17);
                    transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    box-shadow: rgb(17, 247, 17) 0px 1px 0px 1px;
                }
            }

        }
    }

    &.promo {
        .component-shop-product-inner {
            .product-price-container {
                flex-basis: 6rem;
                justify-content: flex-start;
            }

            .product-desc-container {
                flex-basis: 7rem;
                margin-bottom: 0;
            }
        }
    }

    &-inner {
        padding: 2rem;
        height: 100%;
        width: 100%;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;

        .product-name-container {
            text-align: center;
            width: 100%;
            margin-bottom: 4rem;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            flex-grow: 0;
            flex-basis: 2.5rem;
            flex-shrink: 0;

            h4 {
                font-size: 2rem;
                font-family: Gilroy-bold, sans-serif;
                color: #FFFFFF;
                transition: all 1s ease-in-out;
                -webkit-transition: all 1s ease-in-out;
                margin: 0;
            }

        }

        .product-bestseller-info {
            width: 100%;
            text-align: center;
            margin-top: 0rem;
            margin-bottom: 1rem;
            flex-grow: 0;
            flex-basis: 1rem;
            flex-shrink: 0;

            h6 {
                font-size: 0.8rem;
                font-family: Gilroy-semibold, sans-serif;
                color: rgba(0, 224, 255, 0.7);
                margin: 0;
            }
        }

        .product-price-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            margin-bottom: 1.5rem;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-basis: 3.75rem;
            flex-shrink: 0;

            .product-promo-price-wrapper {
                h3 {
                    color: rgb(17, 247, 17);
                    margin: 0;
                }
            }

            .product-old-price-wrapper {
                p {
                    color: #FFFFFF;
                    text-decoration: line-through;
                    margin: 0;
                    font-size: 1.5rem;
                    line-height: 1.6rem;
                    font-family: Gilroy-regular, sans-serif;
                }
            }

            h3 {
                font-size: 3.5rem;
                line-height: 4.2rem;
                font-family: Gilroy-regular, sans-serif;
                color: #FFFFFF;
                transition: all 1s ease-in-out;
                -webkit-transition: all 1s ease-in-out;
                margin: 0;
            }

        }

        .product-desc-container {

            width: 100%;
            text-align: center;
            padding: 0 2rem;
            flex-grow: 0;
            flex-basis: 5rem;
            flex-shrink: 0;
            margin-bottom: 0.5rem;

            p {
                font-family: Gilroy-regular, sans-serif;
                font-size: 1.2rem;
                color: #FFFFFF;
            }
        }

        .product-button-buy-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 0;
            flex-basis: auto;
            flex-shrink: 0;
            width: 100%;
            margin-bottom: 3rem;

            .button {
                min-width: 15rem;
                transition: all 0.2s ease-in-out;
                transform: scale(1);

                &.try.black {
                    padding: 0.2rem;
                    font-size: 0.9rem;
                    opacity: 0.8;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .product-benefits-list-container {
            margin-bottom: 2rem;
            flex-grow: 1;
            flex-basis: auto;
            flex-shrink: 0;

            h3 {
                font-size: 1.1rem;
                background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
                color: #00e0ff;
                margin-bottom: 1rem;
                font-family: Gilroy-bold, sans-serif;
            }

            ul {
                position: relative;
                display: flex;
                flex-direction: column;
                list-style: none;
                font-size: 1.1rem;
                font-family: Gilroy-regular, sans-serif;
                color: #FFF;
                padding-left: 0;
                line-height: 1.5rem;
                padding-left: 20px;

                li {
                    display: list-item;
                    position: relative;
                    margin-bottom: 1rem;
                    padding-right: 30px;

                    img {
                        width: 20px;
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }

                li::before {
                    content: "";
                    position: relative;
                    border-radius: 2px;
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: #00e0ff;
                    margin-right: 0.7rem;
                    margin-left: -20px;
                }

            }
        }

        .product-button-read-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            width: 100%;

            p {
                font-size: 0.7rem;
                margin-bottom: 0.5rem;
                color: rgba(255, 255, 255, 0.7)
            }

            .button {
                min-width: 15rem;
                margin-bottom: 0.5rem;
                transition: all 0.2s ease-in-out;
                transform: scale(1);

                &.try.black {
                    padding: 0.2rem;
                    font-size: 0.9rem;
                    opacity: 0.8;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }

    &.promo {

        &:hover {
            border: 1px solid rgb(17, 247, 17);
            transition: all 0.2s ease-in-out;
            transform: scale(1.05);
            z-index: 2;
            box-shadow: rgb(17, 247, 17, 0.7) 0px 0px 10px 2px, rgba(41, 192, 99, 0.7) 0px 1px 5px 4px;

            .product-name-container {
                h4 {
                    color: #FFF;
                    transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                }

            }
        }

        &.nohover {
            &:hover {
                transform: none;
                box-shadow: none;

                .product-name-container {
                    h4 {
                        color: #FFF;
                        transition: all 0.4s ease-in-out;
                        -webkit-transition: all 0.4s ease-in-out;
                    }

                }
            }

        }

        &.disabled {
            &:hover {
                border: 1px solid rgba(0, 224, 255, 1);
                transition: none;
                transform: none;
                z-index: 2;
                box-shadow: none;
            }
        }

    }
}