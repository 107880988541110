.settings-view-functions-container {
    display: block;
    position: relative;

    .settings-view-functions-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .pane-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: normal;
            flex: 1;

            &:nth-of-type(odd) {

                &:not(:last-of-type) {
                    padding-right: 2rem;
                }
            }

            &:nth-of-type(even) {
                padding-left: 2rem;
            }

            .component-admin-functions-table {
                margin-bottom: 2rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}