.component-checkout-form {
    display: block;
    position: relative;
    width: 100%;

    &-inner {
        .p-PaymentDetails-group {

            button {
                display: none;
                visibility: 0;
            }
        }

        text-align: right;

        button {
            margin-top: 1rem;
        }

        .switcher-and-button-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .currency-switcher {
            color: rgb(150, 151, 151);
            margin-top: 1rem;
            display: inline-block;
            margin-right: 10px;
            /* Odstęp między przełącznikiem a przyciskiem */
            vertical-align: middle;
            /* Aby elementy były wycentrowane względem siebie */

            label {
                margin-right: 0.5rem;
            }
        }

        .currency-switcher select {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
    }
}