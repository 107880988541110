.section-contact {
  padding-top: 2rem;
}
.section-contact .contact-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-contact .component-contact-form {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767px) {
  .section-contact .component-contact-form {
    padding: 0 0.8rem;
  }
}
.section-contact .contact-showcases-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.section-contact .contact-showcases-container div {
  flex-basis: 45%;
}
.section-contact .contact-showcases-container .component-showcase {
  margin-bottom: 1rem;
}
.section-contact .contact-showcases-container .component-showcase-inner .showcase-header-container {
  padding: 0.8rem 3.5rem;
  min-width: 25rem;
}
@media (max-width: 767px) {
  .section-contact .contact-showcases-container .component-showcase-inner .showcase-header-container {
    padding: 0.56rem 2.45rem;
    min-width: 20rem;
  }
}
@media (min-width: 2048px) and (max-width: 3839px) {
  .section-contact {
    padding: 8rem;
  }
}
@media (min-width: 3840px) {
  .section-contact {
    padding: 16rem;
  }
}/*# sourceMappingURL=Contact.css.map */