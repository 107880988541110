.section-hero {
    background-image: url("../../../../assets/img/BG-Main-alpha.webp");
    background-color: rgba(3, 13, 20, 0.6);
    background-size: cover;
    height: 937px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 6rem;
    position: relative;

    
    @media (max-width: 575px) {
        height: 500px;
        padding-bottom: 0.5rem;
        background-position-x: -18rem;
    }

    @media (max-width: 767px) {
        background-image: url("../../../../assets/img/BG-Main-alpha-small.webp");
    }

    @media (min-width: 576px) and (max-width: 767px) {
        height: 550px;
        padding-bottom: 0.5rem;
        background-position-x: -18rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        height: 650px;
        padding-bottom: 1.5rem;
        background-position-x: -18rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        height: 750px;
        padding-bottom: 0.5rem;
        background-position-x: -18rem;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        height: 850px;
        padding-bottom: 4rem;
        background-position-x: -18rem;
    }

    @media(min-width: 2560px) and (max-width: 3839px) {
        height: 1200px;
        padding-bottom: 4rem;
    }

    @media(min-width: 3840px) {
        height: 1720px;
        padding-bottom: 10rem;
    }

    .container {
        $hero-padding: 2rem;

        &.hero {
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: $hero-padding;


            .row {

                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
        }
    }

    .reveal-power-container {
        display: block;
        position: relative;

        &-inner {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .text-hero-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #FFFF;
                filter: drop-shadow(16px 16px 20px #00000080);
                box-shadow: 0px 1px rgb(255, 255, 255);
                padding: 2rem 2rem 2rem 2rem;
                margin-bottom: 2rem;

                h1 {
                    font-size: 5rem;
                    font-family: Gilroy-semibold, sans-serif;
                    margin-bottom: 0;
                    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));

                    @media (max-width: 575px) {
                        font-size: 2rem;
                    }

                    @media (min-width: 576px) and (max-width: 767px) {
                        font-size: 2.5rem;
                    }

                    @media (min-width: 767px) and (max-width: 991px) {
                        font-size: 3.5rem;
                    }

                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 4rem;
                    }
                }
            }

            .button-hero-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.2));

                .button {
                    font-family: Gilroy-semibold, sans-serif;
                }
            }
        }


    }
}