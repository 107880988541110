.component-review {
    display: block;
    position: relative;
    height: 100%;
    border: 1px solid rgba(0,224,255,1);
    border-radius: 7px;
    background-color: rgba(19,19,19, 0.8);
    height: 30rem;
    margin: 1rem;
    &.long-name {
        .component-review-inner {
            .review-info-container {
                margin-right: -1rem;
            }
        }
    }
    &-inner {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        height: 100%;
        width: 100%;
        border-radius: 1rem;
        flex: 1;
        flex-grow: 1;

        .review-personal-container {

            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            width: 100%;
            height: 7rem;
            align-self: flex-start;

            .review-avatar-wrapper {
                border: 1px solid rgba(131, 127, 127, 0.2);
                box-shadow: rgba(131, 127, 127, 0.5) 0px 0px 3px 1px;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                text-align: center;
                overflow: hidden;
                img {
                    width: auto;
                    height: 100%;
                    
                }
            }

            .review-info-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;



                .review-name-wrapper {
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    h4 {
                        font-family: Gilroy-bold, sans-serif;
                        font-size: 2rem;
                        background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        -webkit-background-clip: text;
                        margin: 0;
                      
                    }

                }

                .review-career-wrapper {
                    width: 100%;

                    h5 {
                        font-size: 1rem;
                        font-family: Gilroy-semibold, sans-serif;
                        background: linear-gradient(25deg, rgb(131, 127, 127) 67%, rgba(73, 72, 72, 1) 100%); 
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        -webkit-background-clip: text;
                        
                    }

                }

            }
        }


        .review-text-container {
            margin-top: 2rem;
            width: 100%;
            text-align: center;
            font-family: Gilroy-regular, sans-serif;
            color: rgba(131, 127, 127);
            font-size: 1rem;
            height: 15rem;

        }

        .review-stars-container {
            background-color: transparent;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

    }
}