.section-team {
    padding-top: 0;
    margin-top: -1rem;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;
    .team-members {
        min-height: 15rem;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .team-img-container {
        margin-top: 2rem;
    }

    .member-wrapper {

        @media (max-width: 575px) {
            margin-top: 2rem;
        }

        @media (min-width: 576px) and (max-width: 767px) {
            margin-top: 2rem;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 2rem;
        }

        @media (min-width: 992px) and (max-width: 1199px) {}

        @media (min-width: 1200px) {}
    }

}