.component-prepare-step {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    margin-bottom: 1rem;

    &-inner {
        height: 100%;
        width: 100%;
    }
}