.component-simple-container {
  display: block;
  position: relative;
  height: auto;
  flex: 1;
  border-radius: 7px;
  padding: 1rem;
  background-color: rgba(19, 18, 18, 0.8);
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.component-simple-container.justifyStart .component-simple-container-inner {
  justify-content: flex-start;
}
.component-simple-container.high {
  height: 100%;
}
.component-simple-container.flex {
  margin-top: auto;
  margin-bottom: auto;
}
.component-simple-container.flex .component-simple-container-inner {
  margin-top: auto;
  margin-bottom: auto;
}
.component-simple-container.big {
  min-height: 15rem;
}
.component-simple-container.center .title-wrapper {
  justify-content: center;
}
.component-simple-container.center .title-wrapper .image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}
.component-simple-container.center .title-wrapper .image-wrapper img {
  width: 100%;
  height: auto;
}
.component-simple-container.center .title-wrapper img {
  flex: 1;
}
.component-simple-container.center .title-wrapper h3 {
  text-align: center;
}
.component-simple-container.discord .component-simple-container-inner {
  color: #FFFFFF;
}
.component-simple-container.discord .component-simple-container-inner .title-wrapper {
  color: rgb(88, 101, 242);
}
.component-simple-container.discord .component-simple-container-inner input {
  color: rgb(88, 101, 242);
}
.component-simple-container.discord .component-simple-container-inner .description-wrapper ol li input {
  color: rgb(88, 101, 242);
}
.component-simple-container.discord .component-simple-container-inner .description-wrapper a {
  color: rgb(88, 101, 242);
}
.component-simple-container.messenger .component-simple-container-inner {
  color: #FFFFFF;
}
.component-simple-container.messenger .component-simple-container-inner .title-wrapper {
  color: rgb(207, 66, 191);
}
.component-simple-container.messenger .component-simple-container-inner input {
  color: rgb(207, 66, 191);
}
.component-simple-container.messenger .component-simple-container-inner .description-wrapper ol li input {
  color: rgb(207, 66, 191);
}
.component-simple-container.messenger .component-simple-container-inner a {
  color: #cf42bf;
}
.component-simple-container-inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.component-simple-container-inner .title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 3rem;
  overflow: hidden;
}
.component-simple-container-inner .title-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}
.component-simple-container-inner .title-wrapper img {
  flex: 0.5;
}
.component-simple-container-inner .title-wrapper .image-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.component-simple-container-inner .title-wrapper .image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}
.component-simple-container-inner .title-wrapper .image-wrapper.loading::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  flex: 1;
  height: 100%;
  opacity: 1;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-color: red;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.component-simple-container-inner .title-wrapper .image-wrapper.loading img {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.component-simple-container-inner .title-wrapper h3 {
  flex: 1;
  margin: 0;
  text-align: left;
  align-self: center;
}
.component-simple-container-inner .description-wrapper {
  width: 100%;
}
.component-simple-container-inner .description-wrapper .bottom-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.component-simple-container-inner .description-wrapper .bottom-flex :last-child {
  margin-right: 2rem;
}
.component-simple-container-inner .description-wrapper .bottom-flex p {
  font-family: Gilroy-regular, sans-serif;
  cursor: pointer;
}
.component-simple-container-inner .description-wrapper .bottom-flex.right {
  justify-content: flex-end;
}
.component-simple-container-inner .description-wrapper .clickable {
  max-height: 2rem;
  cursor: pointer;
  font-family: Gilroy-regular, sans-serif;
}
.component-simple-container.button {
  cursor: pointer;
}/*# sourceMappingURL=SimpleContainer.css.map */