.component-side-nav {
    display: block;
    position: relative;
    padding: 1rem 2rem;
    min-height: 35rem;

    @media(max-width: 1199px) {
        min-height: 0rem;
        height: auto;
        padding: 2rem 1rem;
    }

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;

        @media(max-width: 991px) {
            flex-direction: column;
            min-height: auto;
            justify-content: center;
            align-items: center;
        }

        ul {

            list-style-type: none;
            margin: 0;
            padding: 0;

            @media(max-width: 991px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;
            font-family: Gilroy-semibold, sans-serif;
            font-size: 1.2rem;
            width: 100%;
            text-align: left;
            margin-bottom: 2rem;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
            flex-wrap: nowrap;

            @media (max-width: 991px) {
                flex: 1;
                width: auto;
                height: auto;
                margin: 0 0.5rem 0 0;
                float: left;
                font-size: 1.05rem;
                text-align: center;
                line-height: 1.3rem;

                &:last-of-type {
                    margin-bottom: 0rem;
                    margin-right: 0;
                }

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        li a {
            display: block;
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: rgba(255, 255, 255, 1);


            &:hover {
                color: rgb(19, 189, 219);
            }

            @media (max-width: 991px) {
                width: auto;
                height: auto;
            }
        }

        .active {
            color: rgb(19, 189, 219);

            button {
                filter: invert(55%) sepia(96%) saturate(957%) hue-rotate(148deg) brightness(107%) contrast(108%);
            }
        }

        .buttons-container {
            margin-top: 2rem;

            :not(:last-child) {
                margin-bottom: 1.5rem;
            }

            @media (max-width: 991px) {
                display: none;
                visibility: hidden;
            }

            .settings-button-wrapper {
                @media (min-width: 992px) {
                    visibility: visible;
                    display: inline-block;
                }


            }

            .logout-button-wrapper {
                filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
            }
        }


        .list-container {
            margin-top: 4rem;

            @media (max-width: 991px) {
                margin-top: 0rem;
                width: 100%;
            }

        }
    }
}