.component-settings-functions-table {
  display: block;
  position: relative;
}
.component-settings-functions-table-inner .component-settings-function-bar {
  margin-bottom: 0.5rem;
}
.component-settings-functions-table-inner .component-settings-function-bar:last-of-type {
  margin-bottom: 0;
}
.component-settings-functions-table-inner .specific-functions-title {
  display: block;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 7px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.component-settings-functions-table-inner .specific-functions-title h3 {
  font-size: 1.1rem;
  color: #adb5bd;
  font-size: 1rem;
  line-height: 1.1rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  text-align: center;
  margin-bottom: 0;
}/*# sourceMappingURL=SettingsFunctionsTable.css.map */