@charset "UTF-8";
.component-checkout-form {
  display: block;
  position: relative;
  width: 100%;
}
.component-checkout-form-inner {
  text-align: right;
}
.component-checkout-form-inner .p-PaymentDetails-group button {
  display: none;
  visibility: 0;
}
.component-checkout-form-inner button {
  margin-top: 1rem;
}
.component-checkout-form-inner .switcher-and-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.component-checkout-form-inner .currency-switcher {
  color: rgb(150, 151, 151);
  margin-top: 1rem;
  display: inline-block;
  margin-right: 10px;
  /* Odstęp między przełącznikiem a przyciskiem */
  vertical-align: middle;
  /* Aby elementy były wycentrowane względem siebie */
}
.component-checkout-form-inner .currency-switcher label {
  margin-right: 0.5rem;
}
.component-checkout-form-inner .currency-switcher select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}/*# sourceMappingURL=CheckoutForm.css.map */