.section-login-form {

    .spinner-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .col {
        &.center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .input-group {
        .password-toggle {
            display: flex;
            height: auto;
            background-color: rgb(0, 224, 255);
            flex: 0.05;
            justify-content: center;

            img {
                max-width: 100%;
                width: 1.5rem;
                margin: 0;
                padding: 0;
            }
        }
    }

    .form-container {
        display: block;
        border: 1px solid rgba(0, 224, 255, 1);
        border-radius: 0.6rem;
        padding: 5rem;
        min-height: 35rem;
        width: 100%;
        background-color: rgba(19, 18, 18, 0.8);
        box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));

        .form-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            p {
                margin-top: 0.2rem;
                color: #ffffff;
                margin-bottom: 0;
                font-size: 0.8rem;
                font-family: Gilroy-regular, sans-serif;

                a {
                    text-decoration: none;
                    color: #adb5bd;
                }

                &:hover {
                    a {
                        color: #ffffff;
                    }
                }
            }

            .form.login-form {
                width: 100%;

                h1 {
                    font-size: 3rem;
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 224, 255, 1);
                    text-align: center;
                    margin-bottom: 2rem;
                    line-height: 3.1rem;
                }

                .form-label {
                    font-size: 1rem;
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 224, 255, 1);
                    text-align: left;
                    margin-bottom: 0.5rem;

                }

                .form-group {
                    margin-bottom: 1.5rem;
                }

                .button {
                    font-size: 1.1rem;
                }


            }
        }

        @media (max-width:991px) {
            padding: 1rem;

            .form-wrapper {
                .form.login-form {
                    h1 {
                        font-size: calc(0.7 * 3rem);
                    }
                }
            }
        }
    }

    .need-account-text-wrapper {
        margin-top: 0.3rem;
        margin-bottom: 0rem;
        width: 100%;
        text-align: center;

        p {
            color: #ffffff;
            margin-bottom: 0;
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
        }

        a {
            text-decoration: none;
            color: #ffffff;

            .highlighted {

                color: rgba(0, 224, 255, 1);
                font-weight: bold;

            }
        }

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }

    .turnstile-container {
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        iframe {
            border-radius: 7px !important;
            border: 1px solid rgba(0, 224, 255, 1) !important;
        }
    }

    .spinner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
}