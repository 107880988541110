.admin-view-svc-status-container {
  display: block;
  position: relative;
}
.admin-view-svc-status-container input {
  width: 100%;
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: normal;
  height: 100%;
}
@media (max-width: 991px) {
  .admin-view-svc-status-container .admin-view-svc-status-wrapper {
    flex-direction: column;
  }
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .button {
  width: 100%;
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-inputs {
  width: 100%;
  height: 100%;
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-container {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  background-color: rgba(19, 18, 18, 0.8);
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-container:not(:last-of-type) {
  margin-right: 1rem;
}
@media (max-width: 991px) {
  .admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-container {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-container .button {
  margin-top: 1rem;
}
.admin-view-svc-status-container .admin-view-svc-status-wrapper .admin-svc-status-bar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  align-items: center;
}/*# sourceMappingURL=AdminViewSvcStatus.css.map */