.section-use-potential {
  position: relative;
}
.section-use-potential .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.section-use-potential .use-potential-text-wrapper {
  text-align: left;
}
.section-use-potential .container {
  overflow: visible;
}
.section-use-potential .monitor-container {
  display: none;
}
@media (min-width: 1200px) {
  .section-use-potential .monitor-container {
    display: block;
  }
}/*# sourceMappingURL=SectionUsePotential.css.map */