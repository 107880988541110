.section-contact {
    padding-top: 2rem;

    .contact-data-container {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .component-contact-form {
        padding-left: 2rem;
        padding-right: 2rem;

        @media (max-width: 767px) {
            padding: 0 0.8rem;
        }
    }

    .contact-contact-form-container {}

    .contact-showcases-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;


        div {
            flex-basis: 45%;
        }

        .component-showcase {
            margin-bottom: 1rem;

            &-inner {
                .showcase-header-container {
                    padding: 0.8rem 3.5rem;
                    min-width: 25rem;

                    @media (max-width: 767px) {
                        padding: calc(0.7 * 0.8rem) calc(0.7 * 3.5rem);
                        min-width: 20rem;
                    }
                }
            }
        }
    }

    @media (min-width: 2048px) and (max-width: 3839px) {
        padding: 8rem;
    }

    @media (min-width:3840px) {
        padding: 16rem;
    }
}