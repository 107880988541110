.component-polaroid {
    display: block;
    position: relative;
    filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.4));
    border: 1px solid rgba(0, 224, 255, 1);
    width: 100%;
    background-color: rgba(19, 19, 19, 0.8);
    border-radius: 1rem;
    height: 27rem;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    &:hover {

        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        z-index: 2;

        .polaroid-title-container {
            h4 {
                color: rgba(0, 224, 255, 1);
                transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
            }

        }

    }

    &-inner {
        padding: 2rem;
        height: 100%;
        width: 100%;
        border-radius: 1rem;



        .polaroid-icon-container {

            display: flex;
            width: 100%;
            text-align: center;
            height: 12rem;
            align-items: center;
            justify-content: center;
            margin-top: -0.4rem;


            img {
                text-align: center;
                margin: 0;
                padding: 0;
                align-self: center;
            }

        }

        .polaroid-title-container {
            text-align: center;
            width: 100%;
            color: #FFFFFF;
            font-family: Gilroy-bold, sans-serif;
            margin-top: -0.3rem;
            margin-bottom: 1.3rem;

            h4 {
                transition: all 1s ease-in-out;
                -webkit-transition: all 1s ease-in-out;
            }
        }

        .polaroid-text-container {

            width: 100%;
            text-align: center;
            font-family: Gilroy-bold, sans-serif;
            background: linear-gradient(25deg, rgb(131, 127, 127) 67%, rgba(73, 72, 72, 1) 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            transition: all 1s ease-in-out;
            -webkit-transition: all 1s ease-in-out;
        }

    }
}