.component-text {
    display: block;
    position: relative;
    font-family: Gilroy-bold, sans-serif;
    margin-bottom: 2rem;
    filter: drop-shadow(12px 12px 16px rgba(0, 0, 0, 0.7));

    &.center {
        .component-text-inner {
            justify-content: center;
            align-items: center;

            h1,
            h2,
            h3,
            h4,
            p {
                text-align: center;
            }
        }
    }

    &.left {
        .component-text-inner {
            justify-content: left;
            align-items: flex-start;

            h1,
            h2,
            h3,
            h4,
            p {
                text-align: left;
            }
        }
    }

    &.right {
        .component-text-inner {
            justify-content: right;
            align-items: flex-end;

            h1,
            h2,
            h3,
            h4,
            p {
                text-align: right;
            }
        }
    }

    &.subpage {
        margin-top: 2rem;
        margin-bottom: 5rem;

        .component-text-inner {
            h1 {
                font-size: 3rem;
                background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
                color: #00e0ff;
            }

            h2 {
                font-size: 1rem;
                background: linear-gradient(25deg, rgba(255, 253, 253, 0.2) 70%, rgba(255, 255, 255, 0.2) 100%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
            }

            @media (max-width: 575px) {
                h1 {
                    font-size: calc(0.7 * 2.38rem);
                }

                h2 {
                    font-size: calc(0.7 * 1rem);
                }

                h3 {
                    font-size: calc(0.7 * 2.8rem);
                }

                h4 {
                    font-size: calc(0.7 * 2rem);
                }

                h5 {
                    font-size: calc(0.7 * 1.6rem);
                }

                p {
                    font-size: calc(0.7 * 1.4rem);
                }
            }

            @media (min-width: 576px) and (max-width: 767px) {
                h1 {
                    font-size: calc(0.8 * 2.38rem);
                }

                h2 {
                    font-size: calc(0.8 * 1rem);
                }

                h3 {
                    font-size: calc(0.8 * 2.8rem);
                }

                h4 {
                    font-size: calc(0.8 * 2rem);
                }

                h5 {
                    font-size: calc(0.8 * 1.6rem);
                }

                p {
                    font-size: calc(0.8 * 1.4rem);
                }
            }

            @media (min-width: 768px) and (max-width: 991px) {
                h1 {
                    font-size: calc(0.9 * 2.38rem);
                }

                h2 {
                    font-size: calc(0.9 * 1rem);
                }

                h3 {
                    font-size: calc(0.9 * 2.8rem);
                }

                h4 {
                    font-size: calc(0.9 * 2rem);
                }

                h5 {
                    font-size: calc(0.9 * 1.6rem);
                }

                p {
                    font-size: calc(0.9 * 1.4rem);
                }
            }

        }

        p {
            margin-top: 2rem;
        }

        &.white {
            .component-text-inner {
                h2 {
                    font-size: 1rem;
                    background: #ffffff;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    -webkit-background-clip: text;
                }
            }
        }

        @media (max-width: 767px) {
            margin-top: 1rem;
            margin-bottom: 2.5rem;
        }
    }

    &.small {
        margin-top: 4rem;
        padding-top: 2rem;

        p {
            font-size: 1.1rem;
        }

        &:first-of-type {
            margin-top: 4rem;
            padding-top: 2rem;

            @media (max-width: 767px) {
                margin-top: 2rem;
                padding-top: 1rem;
            }
        }

        @media (max-width: 767px) {
            margin-top: 2rem;
            padding-top: 2rem;
        }

    }

    &-inner {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1 {
            font-size: 3.4rem;
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        h2 {
            font-size: 2.5rem;
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        h3 {
            color: #ffffff;
            margin-bottom: 1.5rem;
            font-size: 2.8rem;
            font-family: Gilroy-bold, sans-serif;
        }

        h4 {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            margin-bottom: 1.5rem;
            font-size: 2rem;
        }

        h5 {
            background: rgb(0, 224, 255);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
        }

        p {
            background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgba(255, 255, 255, 1) 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 1.4rem;
            line-height: 1.5rem;
            margin: 0;
            letter-spacing: 0.5px;
            font-family: Gilroy-regular, sans-serif;
        }

        @media (max-width: 575px) {
            h1 {
                font-size: calc(0.7 * 2.38rem);
            }

            h2 {
                font-size: calc(0.7 * 2.5rem);
            }

            h3 {
                font-size: calc(0.7 * 2.8rem);
            }

            h4 {
                font-size: calc(0.7 * 2rem);
            }

            h5 {
                font-size: calc(0.7 * 1.6rem);
            }

            p {
                font-size: calc(0.7 * 1.4rem);
            }
        }

        @media (min-width: 576px) and (max-width: 767px) {
            h1 {
                font-size: calc(0.8 * 2.38rem);
            }

            h2 {
                font-size: calc(0.8 * 2.5rem);
            }

            h3 {
                font-size: calc(0.8 * 2.8rem);
            }

            h4 {
                font-size: calc(0.8 * 2rem);
            }

            h5 {
                font-size: calc(0.8 * 1.6rem);
            }

            p {
                font-size: calc(0.8 * 1.4rem);
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            h1 {
                font-size: calc(0.9 * 2.38rem);
            }

            h2 {
                font-size: calc(0.9 * 2.5rem);
            }

            h3 {
                font-size: calc(0.9 * 2.8rem);
            }

            h4 {
                font-size: calc(0.9 * 2rem);
            }

            h5 {
                font-size: calc(0.9 * 1.6rem);
            }

            p {
                font-size: calc(0.9 * 1.4rem);
            }
        }

        @media (min-width: 1200px) and (max-width: 1399px) {
            h1 {
                font-size: calc(0.9 * 2.38rem);
            }

            h2 {
                font-size: calc(0.9 * 2.5rem);
            }

            h3 {
                font-size: calc(0.9 * 2.8rem);
            }

            h4 {
                font-size: calc(0.9 * 2rem);
            }

            h5 {
                font-size: calc(0.9 * 1.6rem);
            }

            p {
                font-size: calc(0.9 * 1.4rem);
            }
        }

    }
}