.component-instruction-box {
  display: block;
  position: relative;
  min-height: 20rem;
  border: 1px solid rgba(0, 224, 255, 0.5);
  border-radius: 1rem;
  padding: 2rem;
  background-color: rgb(19, 19, 19);
  margin-top: 1.5rem;
}
.component-instruction-box-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}
.component-instruction-box-inner .number-container .number-wrapper {
  background-color: rgb(0, 224, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
}
.component-instruction-box-inner .number-container .number-wrapper h1 {
  font-family: Gilroy-bold, sans-serif;
  line-height: 2.4rem;
  font-size: 2rem;
  color: #000000;
  margin: 0;
  padding: 0;
}
.component-instruction-box-inner .title-container {
  margin-top: 1.2rem;
}
.component-instruction-box-inner .title-container h2 {
  font-family: Gilroy-bold, sans-serif;
  line-height: 1.8rem;
  font-size: 1.4rem;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
}
.component-instruction-box-inner .description-container {
  margin-top: 0.5rem;
}
.component-instruction-box-inner .description-container p {
  font-family: Gilroy-semibold, sans-serif;
  line-height: 1.4rem;
  font-size: 1rem;
  color: rgb(129, 128, 128);
  margin: 0;
  padding: 0;
}/*# sourceMappingURL=InstructionBox.css.map */