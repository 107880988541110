.component-date-holder {
    display: inline-block;
    position: relative;
    border: 1px solid rgb(0, 224, 255);
    padding: 1rem;
    height: 5rem;
    min-width: 5rem;
    border-radius: 7px;
    transition: all 0.1s ease-in-out;
    &:hover {
        border: 1px solid #FFFFFF;
        cursor: pointer;
    }

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        p {
            text-align: center;
            font-size: 1rem;
            color: #FFFFFF;
            line-height: 1.1rem;
            font-family: Gilroy-semibold, sans-serif;
            margin: 0;
            padding: 0;
        }
    }

    &.clicked {
        background-color: rgb(0, 224, 255);

        .component-date-holder-inner {
            p {
                color: #000000;

            }
        }
        &:hover {
            border: 1px solid rgb(0, 224, 255);
        }
    }

   
}