.component-invoice-data-form {
  position: relative;
  display: block;
}
.component-invoice-data-form-inner h1 {
  font-size: 3rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3.1rem;
}
.component-invoice-data-form-inner .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.component-invoice-data-form-inner .form-select.status-select {
  display: flex;
  height: auto;
  background-color: #FFF;
  flex: 0.2;
  justify-content: center;
  color: #212529;
  font-family: Gilroy-semibold, sans-serif;
}
.component-invoice-data-form-inner .phone-input {
  flex: 0.8;
}
.component-invoice-data-form-inner .button {
  margin-top: 2rem;
  font-size: 1rem;
  padding: 0.6rem 2.1rem;
  font-family: Gilroy-semibold, sans-serif;
}
.component-invoice-data-form-inner .button:not(:last-of-type) {
  margin-right: 1rem;
}
.component-invoice-data-form-inner .form-invoice-data-group {
  margin-bottom: 0.7rem;
}
.component-invoice-data-form-inner .form-invoice-data-group.alternative .form-label {
  font-family: Gilroy-regular, sans-serif;
  color: rgba(0, 224, 255, 0.8);
}
.component-invoice-data-form-inner .plaintext {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);
  border: rgba(0, 0, 0, 0.7);
}
.component-invoice-data-form-inner .form-control,
.component-invoice-data-form-inner .form-select {
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
}
.component-invoice-data-form-inner .form-control:disabled,
.component-invoice-data-form-inner .form-select:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
.component-invoice-data-form-inner .form-control.loading,
.component-invoice-data-form-inner .form-select.loading {
  color: transparent;
}
.component-invoice-data-form-inner .form-control.loading::-moz-placeholder, .component-invoice-data-form-inner .form-select.loading::-moz-placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.component-invoice-data-form-inner .form-control.loading::placeholder,
.component-invoice-data-form-inner .form-select.loading::placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}/*# sourceMappingURL=InvoiceDataForm.css.map */