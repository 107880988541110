.component-sponsor-slider {
    display: block;
    position: relative;

    &-inner {

        .each-slide-effect {
            height: 2rem;

            .image-wrapper {
                height: 100%;
                width: 100%;

                img {
                    max-height: 100%;
                }

            }
        }

        .each-slide-effect>div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            height: auto;
            width: auto;

        }

        .each-slide-effect span {
            padding: 20px;
            font-size: 20px;
            background: #efefef;
            text-align: center;
        }

    }
}