.proserwis-navigators-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.proserwis-navigators-container .button {
  margin-bottom: 0.6rem;
}
.proserwis-navigators-container .button:last-of-type {
  margin-bottom: 0;
}
.proserwis-navigators-container .button.try.black {
  border: 1px solid rgba(0, 224, 255, 0.4);
}/*# sourceMappingURL=Proserwis.css.map */