.component-step.step-six .component-step-inner h4 {
  margin-bottom: 2rem;
}
.component-step.step-six .component-step-inner ol.product-box-ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: Gilroy-semibold, sans-serif;
  margin-top: 1rem;
  list-style-position: outside;
  margin-bottom: 2rem;
}
.component-step.step-six .component-step-inner ol.product-box-ol li {
  list-style-type: none;
  counter-increment: item;
  list-style-position: outside;
  margin-bottom: 0.5rem;
  color: #FFF;
  font-family: Gilroy-regular;
  filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.1));
}
.component-step.step-six .component-step-inner ol.product-box-ol li:before {
  content: counter(item);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.31rem;
  background-color: rgb(0, 224, 255);
  border: 1px solid rgb(0, 224, 255);
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 7px;
  width: 2rem;
  height: 2rem;
  font-family: Gilroy-bold, sans-serif;
}/*# sourceMappingURL=StepSix.css.map */