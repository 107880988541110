.component-admin-functions-table {
    display: block;
    position: relative;

    &-inner {
        .component-admin-function-bar {
            margin-bottom: 0.5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .specific-functions-title {
            display: block;
            position: relative;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 7px;
            margin-bottom: 1rem;
            padding: 0.5rem;

            h3 {
                font-size: 1.1rem;
                color: #ffffff;
                text-align: center;
                font-style: italic;
                margin-bottom: 0;
            }
        }
    }
}