.section-products {
  padding-top: 2rem;
}
.section-products .promo-products-text {
  margin-top: 7rem;
}
.section-products .promo-products-container,
.section-products .main-products-container {
  height: auto;
  padding-top: 2.5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
}
.section-products .promo-products-container .shop-product-wrapper,
.section-products .main-products-container .shop-product-wrapper {
  display: flex;
  flex: 1;
}
.section-products .promo-products-container .component-shop-product,
.section-products .main-products-container .component-shop-product {
  flex: 1;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .section-products .promo-products-container,
  .section-products .main-products-container {
    margin-top: -4rem;
  }
}
@media (max-width: 767px) {
  .section-products .promo-products-container .shop-product-wrapper,
  .section-products .main-products-container .shop-product-wrapper {
    margin-top: 4rem;
  }
  .section-products .promo-products-container .shop-product-wrapper:first-of-type,
  .section-products .main-products-container .shop-product-wrapper:first-of-type {
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .section-products .promo-products-container .shop-product-wrapper,
  .section-products .main-products-container .shop-product-wrapper {
    margin-top: 4rem;
  }
}/*# sourceMappingURL=SectionProducts.css.map */