.section-reviews {
  position: relative;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
}
.section-reviews .text-reviews-container {
  text-align: left;
}
.section-reviews .text-reviews-container h1 {
  color: #8ff1ff;
  font-size: 10rem;
  font-family: Gilroy-Bold, sans-serif;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
}
.section-reviews .text-reviews-container h2 {
  color: #FFFFFF;
  font-size: 4rem;
  font-family: Gilroy-Bold, sans-serif;
  margin-top: -1rem;
  margin-bottom: 2rem;
}
.section-reviews .text-reviews-container p {
  color: #adb5bd;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  font-family: Gilroy-Light, sans-serif;
  opacity: 0.5;
}
@media (max-width: 575px) {
  .section-reviews .text-reviews-container {
    margin-bottom: 5rem;
    text-align: center;
  }
  .section-reviews .text-reviews-container h1 {
    font-size: 7rem;
  }
  .section-reviews .text-reviews-container h2 {
    font-size: 2.8rem;
  }
  .section-reviews .text-reviews-container p {
    font-size: 1.05rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-reviews .text-reviews-container {
    margin-bottom: 5rem;
    text-align: center;
  }
  .section-reviews .text-reviews-container h1 {
    font-size: 8rem;
  }
  .section-reviews .text-reviews-container h2 {
    font-size: 3.2rem;
  }
  .section-reviews .text-reviews-container p {
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-reviews .text-reviews-container {
    margin-bottom: 5rem;
    text-align: center;
  }
  .section-reviews .text-reviews-container h1 {
    font-size: 9rem;
  }
  .section-reviews .text-reviews-container h2 {
    font-size: 3.6rem;
  }
  .section-reviews .text-reviews-container p {
    font-size: 1.35rem;
  }
}
.section-reviews .other-reviews-wrapper {
  width: 100%;
  text-align: right;
}
.section-reviews .other-reviews-wrapper a {
  text-decoration: none;
  font-family: Gilroy-Light, sans-serif;
  color: rgba(255, 255, 255, 0.4);
  line-height: 1.5rem;
  font-size: 0.8rem;
  transition: color 0.5s;
  width: 100%;
  margin-left: -2rem;
}
.section-reviews .other-reviews-wrapper a:hover {
  transition: color 0.5s;
  color: rgba(255, 255, 255, 0.8);
}/*# sourceMappingURL=SectionReviews.css.map */