.component-step {
    display: block;
    position: relative;
    &.step-four {
        .form-label {
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
            color: #FFF;
            text-align: left;
            margin-bottom: 0.5rem;
            filter: drop-shadow(1px 1px 1px rgb(0, 0, 0));
        }
    }
}