.component-admin-functions-table {
  display: block;
  position: relative;
}
.component-admin-functions-table-inner .component-admin-function-bar {
  margin-bottom: 0.5rem;
}
.component-admin-functions-table-inner .component-admin-function-bar:last-of-type {
  margin-bottom: 0;
}
.component-admin-functions-table-inner .specific-functions-title {
  display: block;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 7px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.component-admin-functions-table-inner .specific-functions-title h3 {
  font-size: 1.1rem;
  color: #ffffff;
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
}/*# sourceMappingURL=AdminFunctionsTable.css.map */