.section-signup-form {
    .form-container {
        border: 1px solid rgba(0, 224, 255, 1);
        border-radius: 0.6rem;
        padding: 5rem;
        padding-bottom: 3rem;
        min-height: 35rem;
        width: 100%;
        background-color: rgba(19, 18, 18, 0.8);
        box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));

        .form-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .turnstile-container {
                margin-top: 1.5rem;
                margin-bottom: 2rem;

                iframe {
                    border-radius: 7px !important;
                    border: 1px solid rgba(0, 224, 255, 1) !important;
                }
            }

            .form.signup-form {
                width: 100%;

                h1 {
                    font-size: 3rem;
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 224, 255, 1);
                    text-align: center;
                    margin-bottom: 2rem;
                    line-height: 3.1rem;
                }

                .form-label {
                    font-size: 1rem;
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 224, 255, 1);
                    text-align: left;
                    margin-bottom: 0.5rem;

                }

                .input-group {
                    .password-toggle {
                        display: flex;
                        height: auto;
                        background-color: rgb(0, 224, 255);
                        flex: 0.05;
                        justify-content: center;

                        img {
                            max-width: 100%;
                            width: 1.5rem;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                .button {
                    font-size: 1.1rem;
                }

                .form-group {
                    margin-bottom: 1.5rem;
                }
            }


        }


        @media (max-width:991px) {
            padding: 1rem;

            .form-wrapper {
                .form.signup-form {
                    h1 {
                        font-size: calc(0.7 * 3rem);
                    }
                }
            }
        }
    }

    .have-account-text-wrapper {
        margin-top: 0.3rem;
        width: 100%;
        height: 5%;
        text-align: center;

        p {
            color: #ffffff;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
        }

        a {
            text-decoration: none;

            .highlighted {

                color: rgba(0, 224, 255, 1);
                font-weight: bold;

            }
        }
    }

    .choose-option-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;

        .button {
            margin-bottom: 1rem;
        }

        &:last-of-type {
            margin-bottom: 1.3rem;
        }

        p {
            margin-bottom: 1rem;
            color: white;
        }

        .options-container {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border-radius: 7px;
            flex-wrap: wrap;
            transition: all 0.3s ease;

            .option-wrapper {
                * {
                    transition: all 0.3s ease;
                }

                .dot {
                    height: 20px;
                    width: 20px;
                    max-height: 20px;
                    max-width: 20px;
                    background: rgb(150, 151, 151);
                    border-radius: 4px;
                    position: relative;
                    margin-right: 0.7rem;
                    z-index: 1005;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 4px;
                        left: 4px;
                        width: 12px;
                        height: 12px;
                        background: rgb(0, 0, 0);
                        border-radius: 4px;
                        opacity: 0;
                        transform: scale(1.5);
                        transition: all 0.3s ease;
                    }
                }

                .option {

                    background: transparent;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 7px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    min-width: 13rem;

                    &.preferable {

                        border: 2px solid rgb(0, 224, 255);

                        span {
                            color: rgb(0, 224, 255);
                        }
                    }

                    span {
                        font-size: 0.8rem;
                        color: rgb(150, 151, 151);
                        z-index: 1;

                        a {
                            text-decoration: none;
                            color: rgb(150, 151, 151);
                        }
                    }

                }

                input[type="radio"],
                input[type="checkbox"] {
                    display: none;
                    transition: all 0.3s ease-in-out;
                }

                input:checked:checked~.option {
                    color: rgb(0, 224, 255);
                }

                input:checked:checked~.option .dot {
                    background: #fff;
                }

                input:checked:checked~.option .dot::before {
                    opacity: 1;
                    transform: scale(1);
                }

                input:checked:checked~.option span {
                    color: #FFFFFF;

                    a {
                        color: #FFFFFF;
                    }

                }

            }

        }



    }

    .spinner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
}