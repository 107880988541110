.login {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.login .section-login-form {
  padding: 0;
  height: 100%;
}
@media (min-width: 2048px) and (max-width: 3839px) {
  .login .section-login-form {
    padding: 8rem 4rem;
  }
}
@media (min-width: 3840px) {
  .login .section-login-form {
    padding: 8rem 4rem;
  }
}/*# sourceMappingURL=Login.css.map */