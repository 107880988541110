.view-my-services-container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.view-my-services-container .view-my-services-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.view-my-services-container .view-my-services-wrapper .alert {
  width: 100%;
}
.view-my-services-container .view-my-services-wrapper .component-my-service-bar {
  margin-bottom: 0.8rem;
}
.view-my-services-container .view-my-services-wrapper .component-my-service-bar:last-of-type {
  margin-bottom: 0;
}
.view-my-services-container .view-my-services-wrapper .component-my-service-bar-sceletor {
  margin-bottom: 0.8rem;
}
.view-my-services-container h2 {
  margin-top: 5rem;
  font-size: 2.2rem;
  background: #ffffff;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: "Gilroy-bold", sans-serif;
  margin-bottom: 1rem;
  text-align: center;
}/*# sourceMappingURL=ViewMyServices.css.map */