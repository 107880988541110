.social-panel {
    position: fixed;
    left: -9.375rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    border-left: 0;
    border-radius: 0 7px 7px 0;
    transition: transform 0.3s ease, opacity 0.5s ease-in-out;
    z-index: 1000;
    width: 12.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    opacity: 1;

    .text-container {
        font-family: Gilroy-bold, sans-serif;
        color: white;
        font-size: 0.8rem;
        text-align: center;

        p {
            padding: 0;
            margin: 0;
        }
    }

    @media (max-width:991px) {
        visibility: hidden;
    }
}

.social-panel.open {
    transform: translateY(-50%) translateX(150px);
}


.socials-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
}

.icon {
    width: 2.5rem;
    /* Możesz dostosować rozmiar ikony */
    height: auto;
    /* Zachowuje proporcje ikony */
    transition: transform 0.3s ease;
    border: 1px solid black;
    border-radius: 12px;
}

.icon:hover {
    transform: scale(1.1);
}

.toggle-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;

    img {
        width: 40px;
        transition: transform 0.3s ease;
    }

    &.open img {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
    }
}