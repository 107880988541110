.settings-view-password-container {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}
.settings-view-password-container .settings-view-password-wrapper {
  display: flex;
  flex-direction: column;
}
.settings-view-password-container .settings-view-password-wrapper button:not(:last-of-type) {
  margin-bottom: 1rem;
}
.settings-view-password-container .form.settings-view-form {
  margin-bottom: 2rem;
}
.settings-view-password-container .form.settings-view-form .form-group {
  margin-bottom: 1rem;
}
.settings-view-password-container .form.settings-view-form .input-group .form-control.password-toggle {
  display: flex;
  height: auto;
  background-color: rgb(0, 224, 255);
  flex: 0.05;
  justify-content: center;
}
.settings-view-password-container .form.settings-view-form .input-group .form-control.password-toggle img {
  max-width: 100%;
  width: 1.5rem;
  margin: 0;
  padding: 0;
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control {
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control.loading {
  color: transparent;
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control.loading::-moz-placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control.loading::placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.settings-view-password-container .form.settings-view-form .form-group.settings .form-control.plaintext {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);
  border: rgba(0, 0, 0, 0.7);
}/*# sourceMappingURL=SettingsViewPassword.css.map */