.component-hiding-image {
    display: block;
    position: relative;
    z-index: 2;
    filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));

    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .text-wrapper {
            padding-top: 25%;
            display: block;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 2rem;
            right: 2rem;
            margin: auto;
            cursor: default;
            text-align: center;
            opacity: 1;
            @media (max-width: 991px) {

                padding-top: 5%;
            }

            p {
                color: #ffffff;
                font-family: Gilroy-Light, sans-serif;
                line-height: 1.5rem;

                @media (max-width: 991px) {
                    font-size: 0.98rem;
                }
            }
        }

        .image-wrapper {

            visibility: visible;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: grayscale(0) blur(0);
            img {
                width: 90%;

            }
        }


        /* === Enter Transition === */

        .myClass-enter {
            opacity: 0;
            visibility: hidden;

        }

        .myClass-enter-active {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.6s ease-in-out;
            -o-transition: opacity 0.6s ease-in-out;
            /* opera */
            -ms-transition: opacity 0.6s ease-in-out;
            /* IE 10 */
            -moz-transition: opacity 0.6s ease-in-out;
            /* Firefox */
            -webkit-transition: opacity 0.6s ease-in-out;
            /*safari and chrome */

        }

        .myClass-enter-done {
            opacity: 1;
            visibility: visible;

        }

        /* === Exit Transition === */

        .myClass-exit {
            opacity: 1;
            visibility: visible;
            transition: visibility 0.4s, opacity 0.4s ease-in-out;
            -o-transition: visibility 0.6s, opacity 0.6s ease-in-out;
            /* opera */
            -ms-transition: visibility 0.6s, opacity 0.6s ease-in-out;
            /* IE 10 */
            -moz-transition: visibility 0.6s, opacity 0.6s ease-in-out;
            /* Firefox */
            -webkit-transition: visibility 0.4s, opacity 0.4s ease-in-out;
            /*safari and chrome */
        }

        .myClass-exit-active {
            opacity: 0;
            visibility: visible;



        }

        .myClass-exit-done {
            opacity: 0;
            visibility: hidden;

        }
    }
}