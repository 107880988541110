.gm-how-it-works {
    padding-top: 0;
}

.gm-image-container {
    display: none;
    
    @media (min-width: 1200px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}