.component-prepare-step {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 1rem;
}
.component-prepare-step-inner {
  height: 100%;
  width: 100%;
}/*# sourceMappingURL=PrepareStep.css.map */