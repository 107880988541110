.section-hero {
  background-image: url("../../../../assets/img/BG-Main-alpha.webp");
  background-color: rgba(3, 13, 20, 0.6);
  background-size: cover;
  height: 937px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 6rem;
  position: relative;
}
@media (max-width: 575px) {
  .section-hero {
    height: 500px;
    padding-bottom: 0.5rem;
    background-position-x: -18rem;
  }
}
@media (max-width: 767px) {
  .section-hero {
    background-image: url("../../../../assets/img/BG-Main-alpha-small.webp");
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-hero {
    height: 550px;
    padding-bottom: 0.5rem;
    background-position-x: -18rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-hero {
    height: 650px;
    padding-bottom: 1.5rem;
    background-position-x: -18rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-hero {
    height: 750px;
    padding-bottom: 0.5rem;
    background-position-x: -18rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .section-hero {
    height: 850px;
    padding-bottom: 4rem;
    background-position-x: -18rem;
  }
}
@media (min-width: 2560px) and (max-width: 3839px) {
  .section-hero {
    height: 1200px;
    padding-bottom: 4rem;
  }
}
@media (min-width: 3840px) {
  .section-hero {
    height: 1720px;
    padding-bottom: 10rem;
  }
}
.section-hero .container.hero {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2rem;
}
.section-hero .container.hero .row {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.section-hero .reveal-power-container {
  display: block;
  position: relative;
}
.section-hero .reveal-power-container-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section-hero .reveal-power-container-inner .text-hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5019607843));
  box-shadow: 0px 1px rgb(255, 255, 255);
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 2rem;
}
.section-hero .reveal-power-container-inner .text-hero-wrapper h1 {
  font-size: 5rem;
  font-family: Gilroy-semibold, sans-serif;
  margin-bottom: 0;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}
@media (max-width: 575px) {
  .section-hero .reveal-power-container-inner .text-hero-wrapper h1 {
    font-size: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-hero .reveal-power-container-inner .text-hero-wrapper h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .section-hero .reveal-power-container-inner .text-hero-wrapper h1 {
    font-size: 3.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-hero .reveal-power-container-inner .text-hero-wrapper h1 {
    font-size: 4rem;
  }
}
.section-hero .reveal-power-container-inner .button-hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.2));
}
.section-hero .reveal-power-container-inner .button-hero-wrapper .button {
  font-family: Gilroy-semibold, sans-serif;
}/*# sourceMappingURL=SectionHero.css.map */