.component-statute-paragraph {
    display: block;
    position: relative;
    margin-bottom: 5rem;
    margin-top: 5rem;
    width: 100%;
    height: 100%;

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h1 {
            font-size: 2rem;
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            margin-top: 4rem;
            margin-bottom: 4rem;
            align-self: center;
            text-align: center;
            font-family: Gilroy-semibold, sans-serif;
            display: inline-block;

            &:first-of-type {
                margin-top: 0;
            }

        }

        p {
            background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgba(255, 255, 255, 1) 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 1.1rem;
            line-height: 1.4rem;
            margin: 0;
            letter-spacing: 0.5px;
            font-family: Gilroy-regular, sans-serif;
            margin-bottom: 1rem;
            text-align: left;

        }

        ol {
            counter-reset: item;
            list-style: none;
            margin: 0;
            letter-spacing: 0.5px;
            font-family: Gilroy-regular, sans-serif;
            margin-bottom: 1rem;
            display: block;
            width: 100%;
            padding-left: 0;

            ol {
                margin-left: 0;
                padding-left: 0;
            }

            li {
                display: block;
                font-size: 1.1rem;
                line-height: 1.4rem;
                margin: 0;
                letter-spacing: 0.5px;
                font-family: Gilroy-regular, sans-serif;
                margin-bottom: 1rem;
                text-align: left;
                background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgba(255, 255, 255, 1) 100%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;

                &::before {
                    content: counters(item, ".") ". ";
                    counter-increment: item;
                }

                &.headline {
                    margin-top: 4rem;
                    text-align: center;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &::before {
                        content: counters(item, ".") ". ";
                        counter-increment: item;
                        font-size: 2rem;
                        background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        -webkit-background-clip: text;
                        font-family: Gilroy-semibold, sans-serif;
                    }
                }
            }

            .sub-list {
                counter-reset: sub-list;
                list-style: lower-alpha;
                li {
                    counter-reset: item;
                    counter-increment: sub-list;
                    list-style-type: lower-alpha;
                    &::before {
                        content: "· "; // Ustawienie treści przed elementem
                    }
                }
              
            }
        }


    }

    &.modal-window {
        margin-top: 1.5rem;

        .component-statute-paragraph-inner {
            -webkit-text-fill-color: inherit;

            h1 {
                font-size: 1.4rem;
                margin-bottom: 2.5rem;
                align-self: center;
                text-align: center;
                font-family: Gilroy-semibold, sans-serif;
                display: inline-block;
                background: none;
                color: #000000;
                -webkit-text-fill-color: inherit;

                &:first-of-type {
                    margin-top: 0;
                }

            }

            p {
                -webkit-text-fill-color: inherit;
                font-size: 1rem;
                line-height: 1.4rem;
                margin: 0;
                letter-spacing: 0.5px;
                font-family: Gilroy-regular, sans-serif;
                margin-bottom: 1rem;
                text-align: left;

            }

            ol {
                counter-reset: item;
                list-style: none;
                margin: 0;
                letter-spacing: 0.5px;
                font-family: Gilroy-regular, sans-serif;
                margin-bottom: 1rem;
                display: block;
                width: 100%;
                padding-left: 0;

                ol {
                    margin-left: 0;
                    padding-left: 0;
                }

                li {
                    display: block;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    margin: 0;
                    letter-spacing: 0.5px;
                    font-family: Gilroy-regular, sans-serif;
                    margin-bottom: 1rem;
                    text-align: left;
                    -webkit-text-fill-color: inherit;


                    &::before {
                        content: counters(item, ".") ". ";
                        counter-increment: item;
                    }

                    &.headline {
                        margin-top: 4rem;
                        text-align: center;

                        &:first-of-type {
                            margin-top: 0;
                        }

                        &::before {
                            content: counters(item, ".") ". ";
                            counter-increment: item;
                            font-size: 1.4rem;
                            -webkit-text-fill-color: inherit;
                            font-family: Gilroy-semibold, sans-serif;
                        }
                    }
                }
            }

        }
    }
}