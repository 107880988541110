.component-order-step.step-one {
    .component-order-step-inner {
        .choose-option-container.flex-start.small {
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .options-container {
                .option-wrapper {
                    &:not(:last-of-type) {
                        margin-right: 0.5rem;
                    }

                    input:checked~.option {
                        span {
                            color: black;
                        }
                    }

                    .option {
                        padding: 0.6rem 1rem;
                        font-size: 0.9rem;
                        line-height: 1rem;
                        min-width: 8rem;
                        border: 1px solid rgb(0, 224, 255);

                        span {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}