footer {
  display: block;
  position: relative;
  background-color: rgba(3, 13, 20, 0.6);
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.7);
  flex-shrink: 0;
}
footer .footer-wrapper {
  padding: 3rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
footer .footer-wrapper .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  footer .footer-wrapper .row {
    flex-direction: column;
  }
}
footer .footer-wrapper .row .col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}
footer .footer-wrapper .row .col p,
footer .footer-wrapper .row .col a {
  text-align: center;
  text-decoration: none;
  margin: 0 1rem;
  padding: 0;
  font-size: 1rem;
  color: #adb5bd;
  font-family: Gilroy-regular, sans-serif;
  line-height: 1.1rem;
}
@media (max-width: 1399px) {
  footer .footer-wrapper .row .col p,
  footer .footer-wrapper .row .col a {
    margin: 0 0.65rem;
  }
}
footer .footer-wrapper .row .col.left {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  footer .footer-wrapper .row .col.left {
    justify-content: center;
  }
}
footer .footer-wrapper .row .col.left p:first-child,
footer .footer-wrapper .row .col.left a:first-child {
  margin-left: 0;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}
footer .footer-wrapper .row .col.right {
  justify-content: flex-end;
}
@media (max-width: 991px) {
  footer .footer-wrapper .row .col.right {
    justify-content: center;
  }
}
footer .footer-wrapper .row .col.right p:last-child,
footer .footer-wrapper .row .col.right a:last-child {
  margin-left: 1rem;
  margin-right: 0rem;
  margin-top: 0;
  margin-bottom: 0;
}
footer .footer-wrapper .row .col.right .icons-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 1rem;
}
footer .footer-wrapper .row .col.right .icons-holder a {
  margin-left: 5px;
  margin-right: 0;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
footer .footer-wrapper .row .col.right .icons-holder a img {
  width: 100%;
  height: 100%;
}
footer .footer-wrapper .row .col.right .icons-holder a:first-child {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 575px) {
  footer .footer-wrapper .row .col.right .icons-holder {
    margin-left: 0;
  }
  footer .footer-wrapper .row .col.right .icons-holder a:nth-child(n) {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  footer .footer-wrapper .row .col {
    flex-direction: column;
  }
  footer .footer-wrapper .row .col p:nth-child(n),
  footer .footer-wrapper .row .col a:nth-child(n) {
    margin-bottom: 1rem;
  }
}
footer .footer-wrapper .logotype-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 9rem;
}
footer .footer-wrapper .logotype-wrapper a {
  width: 100%;
  height: auto;
}/*# sourceMappingURL=Footer.css.map */