.proserwis {}

.proserwis-navigators-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .button {
        margin-bottom: 0.6rem;


        &:last-of-type {
            margin-bottom: 0;
        }

        &.try.black {
            border: 1px solid rgba(0, 224, 255, 0.4);
        }
    }

}