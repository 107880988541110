.component-simple-container {
    display: block;
    position: relative;
    height: auto;
    flex: 1;
    border-radius: 7px;
    padding: 1rem;
    background-color: rgba(19, 18, 18, 0.8);
    box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    &.justifyStart {
        .component-simple-container-inner {
            justify-content: flex-start;
        }
    }
    &.high {
        height: 100%;
    }

    &.flex {
        margin-top: auto;
        margin-bottom: auto;

        .component-simple-container-inner {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &.big {
        min-height: 15rem;
    }

    &.center {

        .title-wrapper {
            .image-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                flex: 1;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            img {
                flex: 1;
            }

            justify-content: center;

            h3 {
                text-align: center;
            }
        }

    }

    &.discord {
        .component-simple-container-inner {
            color: #FFFFFF;

            .title-wrapper {
                color: rgb(88, 101, 242);
            }

            input {
                color: rgb(88, 101, 242);
            }

            .description-wrapper {
                ol {
                    li {
                        input {
                            color: rgb(88, 101, 242);
                        }
                    }
                }

                a {
                    color: rgb(88, 101, 242);
                }
            }
        }
    }

    &.messenger {
        .component-simple-container-inner {
            color: #FFFFFF;

            .title-wrapper {
                color: rgb(207, 66, 191);
            }

            input {
                color: rgb(207, 66, 191);
            }

            .description-wrapper {
                ol {
                    li {
                        input {
                            color: rgb(207, 66, 191);
                        }
                    }
                }
            }

            a {
                color: #cf42bf;
            }
        }
    }


    &-inner {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        .title-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            height: 3rem;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            img {
                flex: 0.5;
            }

            .image-wrapper {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    opacity: 1;
                }

                &.loading {
                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        flex: 1;
                        height: 100%;
                        opacity: 1;
                        left: 0;
                        right: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: red;
                        background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
                        border-radius: 7px;
                        background-size: 200% 100%;
                        animation: 0.5s loading linear infinite;
                    }

                    img {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s ease-in-out;
                    }
                }

            }

            h3 {
                flex: 1;
                margin: 0;
                text-align: left;
                align-self: center;
            }
        }

        .description-wrapper {
            width: 100%;


            .bottom-flex {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                :last-child {
                    margin-right: 2rem;
                }

                p {
                    font-family: Gilroy-regular, sans-serif;
                    cursor: pointer;
                }

                &.right {
                    justify-content: flex-end;
                }
            }

            .clickable {
                max-height: 2rem;
                cursor: pointer;
                font-family: Gilroy-regular, sans-serif;
            }
        }
    }

    &.button {
        cursor: pointer;
    }

}