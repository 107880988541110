.section-stars-and-teams {
  position: relative;
}
.section-stars-and-teams .row .hiding-image-wrapper {
  margin-top: 2rem;
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-stars-and-teams .row .hiding-image-wrapper:nth-of-type(2) {
    margin-top: 3rem;
  }
}
.section-stars-and-teams .stars-container,
.section-stars-and-teams .teams-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}
.section-stars-and-teams .stars-container .component-hiding-image,
.section-stars-and-teams .teams-container .component-hiding-image {
  height: 30rem;
}
@media (max-width: 575px) {
  .section-stars-and-teams .stars-container .component-hiding-image,
  .section-stars-and-teams .teams-container .component-hiding-image {
    height: 19rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-stars-and-teams .stars-container .component-hiding-image,
  .section-stars-and-teams .teams-container .component-hiding-image {
    height: 24rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-stars-and-teams .stars-container .component-hiding-image,
  .section-stars-and-teams .teams-container .component-hiding-image {
    height: 31rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-stars-and-teams .stars-container .component-hiding-image,
  .section-stars-and-teams .teams-container .component-hiding-image {
    height: 23rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .section-stars-and-teams .stars-container .component-hiding-image,
  .section-stars-and-teams .teams-container .component-hiding-image {
    height: 26rem;
  }
}
@media (max-width: 991px) {
  .section-stars-and-teams .stars-container #stars-headline,
  .section-stars-and-teams .teams-container #stars-headline {
    margin-top: -3rem;
  }
}
.section-stars-and-teams .stars-container .headline-wrapper,
.section-stars-and-teams .teams-container .headline-wrapper {
  height: 3rem;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
}
.section-stars-and-teams .stars-container .headline-wrapper h3,
.section-stars-and-teams .teams-container .headline-wrapper h3 {
  font-family: Gilroy-bold, sans-serif;
  font-size: 2.5rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-align: center;
}
@media (max-width: 575px) {
  .section-stars-and-teams .stars-container .headline-wrapper h3,
  .section-stars-and-teams .teams-container .headline-wrapper h3 {
    font-size: 1.8rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-stars-and-teams .stars-container .headline-wrapper h3,
  .section-stars-and-teams .teams-container .headline-wrapper h3 {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-stars-and-teams .stars-container .headline-wrapper h3,
  .section-stars-and-teams .teams-container .headline-wrapper h3 {
    font-size: 2rem;
  }
}/*# sourceMappingURL=SectionStarsAndTeams.css.map */