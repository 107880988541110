.menu-wrapper {
  /* Position and sizing of burger button */
  /* Color/shape of burger icon bars */
  /* Color/shape of burger icon bars on hover*/
  /* Position and sizing of clickable cross button */
  /* Color/shape of close button cross */
  /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
  /* General sidebar styles */
  /* Morph shape necessary with bubble or elastic */
  /* Wrapper for item list */
  /* Individual item */
  /* Styling of overlay */
}
.menu-wrapper .language-selector {
  margin-left: 8px;
}
.menu-wrapper .bm-burger-button {
  opacity: 1;
  display: block;
  padding: 1rem 0 1rem 1rem;
}
@media (min-width: 992px) {
  .menu-wrapper .bm-burger-button {
    display: none;
  }
}
.menu-wrapper .social-info-container p {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}
.menu-wrapper .social-info-container p:last-of-type {
  margin-bottom: 0.5rem;
}
.menu-wrapper .social-info-container a {
  display: block;
}
.menu-wrapper .bm-icon {
  width: 50px;
  height: 50px;
}
.menu-wrapper .bm-burger-button-custom {
  color: rgb(0, 224, 255);
  position: relative;
}
.menu-wrapper .bm-burger-bars {
  background: #3d3e42;
}
.menu-wrapper .bm-burger-bars :hover {
  background: white;
}
.menu-wrapper .bm-burger-bars-hover {
  background: rgb(255, 255, 255);
}
.menu-wrapper .bm-cross-button {
  margin-right: 1rem;
  margin-top: 1rem;
  height: 2rem;
  width: 2rem;
}
.menu-wrapper .bm-cross {
  background: #bdc3c7;
}
.menu-wrapper .bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: 0px;
  top: 0px;
}
.menu-wrapper .bm-menu {
  background: rgba(0, 0, 0, 0.98);
  padding: 1.2em 0.5em 0;
  font-size: 1.5rem;
}
.menu-wrapper .bm-morph-shape {
  fill: #373a47;
}
.menu-wrapper .bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  font-family: Gilroy-regular, sans-serif;
}
.menu-wrapper .bm-item {
  display: inline-block;
  padding: 0.5em;
  color: #b8b6b6;
  text-decoration: none;
}
.menu-wrapper .bm-item a {
  text-decoration: none;
}
.menu-wrapper .bm-item:hover {
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.menu-wrapper .menu-buttons-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.menu-wrapper .menu-buttons-container button img {
  filter: invert(50%) brightness(90%);
}
.menu-wrapper .menu-buttons-container :not(:first-child) {
  margin-left: 1rem;
}
.menu-wrapper .menu-item {
  font-family: Gilroy-regular, sans-serif;
}
.menu-wrapper .menu-item img {
  max-width: 100%;
  filter: invert(50%) brightness(90%);
}
.menu-wrapper .menu-item-white {
  font-family: Gilroy-semibold, sans-serif;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.menu-wrapper .menu-item-logout {
  font-family: Gilroy-regular, sans-serif;
}
.menu-wrapper .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
}/*# sourceMappingURL=MobileMenu.css.map */