.gm-main {
    position: relative;
    padding-top: 2rem;

    @media (max-width: 1200px) {
        padding-bottom: 2rem;
    }

    .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

    }

    .only-pc-text {
        display: none;
        font-size: 12px;
        color: rgb(150, 151, 151);
        margin-left: 0.05rem;

    }
    
    @media only screen and (min-width: 769px) {
        .only-pc-text.pc{
            display: block;
        }
    }

    @media only screen and (max-width: 768px) {
        .only-pc-text {
            display: block;
        }
    }

    .download-buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .button {
            margin-right: 1rem;
        }
    }

    .gm-image-container {

        @media (max-width: 992px) {
            margin-top: 2rem;
        }

        display: flex;
        justify-content: center;
        align-items: center;

    }
}