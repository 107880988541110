.section-try-now {
  position: relative;
}
.section-try-now .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.section-try-now .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-try-now .try-now-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
}
.section-try-now .try-now-buttons-wrapper .button {
  margin: 1rem;
}
@media (max-width: 575px) {
  .section-try-now .try-now-buttons-wrapper .button {
    margin: 0rem 0.2rem 0rem 0rem;
  }
  .section-try-now .try-now-buttons-wrapper .button:last-of-type {
    margin: 0;
  }
}
.section-try-now .container {
  overflow: visible;
}
.section-try-now .monitor-cointainer {
  overflow: visible;
}
.section-try-now .partners-text-wrapper {
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
}
.section-try-now .partners-text-wrapper h4 {
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-family: Gilroy-regular, "sans-serif";
}
.section-try-now .partners-logos-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
}
.section-try-now .partners-logos-container .logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.section-try-now .partners-logos-container .logo-wrapper.big {
  width: 14rem;
}
.section-try-now .partners-logos-container .logo-wrapper:first-of-type {
  margin-left: 0;
}
.section-try-now .partners-logos-container .logo-wrapper:last-of-type {
  margin-right: 0;
}/*# sourceMappingURL=SectionTryNow.css.map */