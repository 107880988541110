.section-what-we-improve {
  position: relative;
}
.section-what-we-improve .center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.section-what-we-improve .component-polaroid {
  margin-bottom: 2rem;
}
.section-what-we-improve .polaroid-wrapper .component-polaroid img {
  width: 15rem;
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-what-we-improve .polaroid-wrapper .component-polaroid img {
    width: 17rem;
  }
}
@media (max-width: 767px) {
  .section-what-we-improve .polaroid-wrapper {
    padding: 0 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-what-we-improve .polaroid-wrapper {
    padding: 0 6rem;
  }
}
.section-what-we-improve .polaroids-container {
  margin-top: 5rem;
}
@media (max-width: 575px) {
  .section-what-we-improve .polaroids-container {
    margin-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-what-we-improve .polaroids-container {
    margin-top: 3rem;
  }
}
.section-what-we-improve #proserwis-header {
  animation: shine 3s infinite linear;
  font-size: 5rem;
  background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
@media (max-width: 575px) {
  .section-what-we-improve #proserwis-header {
    font-size: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-what-we-improve #proserwis-header {
    font-size: 4rem;
  }
}
@keyframes shine {
  0% {
    background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
    background-position: 0px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  25% {
    background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
    background-position: 170px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  50% {
    background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
    background-position: 170px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  75% {
    background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
    background-position: 464px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  100% {
    background: linear-gradient(190deg, rgb(255, 255, 255) 48%, rgb(143, 241, 255) 60%);
    background-position: 464px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}/*# sourceMappingURL=SectionWhatWeImprove.css.map */