.component-order-step.step-one .component-order-step-inner .choose-option-container.flex-start.small {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.component-order-step.step-one .component-order-step-inner .choose-option-container.flex-start.small .options-container .option-wrapper:not(:last-of-type) {
  margin-right: 0.5rem;
}
.component-order-step.step-one .component-order-step-inner .choose-option-container.flex-start.small .options-container .option-wrapper input:checked ~ .option span {
  color: black;
}
.component-order-step.step-one .component-order-step-inner .choose-option-container.flex-start.small .options-container .option-wrapper .option {
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  line-height: 1rem;
  min-width: 8rem;
  border: 1px solid rgb(0, 224, 255);
}
.component-order-step.step-one .component-order-step-inner .choose-option-container.flex-start.small .options-container .option-wrapper .option span {
  color: white;
}/*# sourceMappingURL=OrderStepOne.css.map */