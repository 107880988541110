.component-order-step.step-two {
  min-height: 25rem;
  height: auto;
  text-align: center;
}
.component-order-step.step-two .component-order-step-inner {
  justify-content: flex-start;
  align-items: center;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-bottom: 1rem;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container > *:not(:last-child) {
  margin-right: 1rem;
}
@media (max-width: 575px) {
  .component-order-step.step-two .component-order-step-inner .accept-and-discount-container {
    flex-direction: column;
    width: 14.5rem;
  }
  .component-order-step.step-two .component-order-step-inner .accept-and-discount-container > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code {
  box-shadow: 0;
  filter: 0;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .input-group {
  flex-wrap: nowrap;
  width: 100%;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code input.form-control {
  border: 2px solid rgb(150, 151, 151);
  border-radius: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  font-family: Gilroy-semibold, sans-serif;
  filter: none;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code input.form-control:focus {
  outline: none;
  box-shadow: none;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(150, 151, 151);
  border-radius: 7px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: rgb(0, 224, 255);
  cursor: pointer;
  width: 4rem;
  padding: 0.375rem 0.75rem;
  min-height: 3rem;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .button:focus {
  outline: none;
  box-shadow: none;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .button .spinner {
  width: 16px;
  height: 16px;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .button img {
  width: 26px;
  height: 26px;
}
.component-order-step.step-two .component-order-step-inner .accept-and-discount-container .form-discount-code .input-group {
  height: 100%;
}
.component-order-step.step-two .component-order-step-inner p {
  font-family: Gilroy-semibold, sans-serif;
  margin-bottom: 0.8rem;
  margin-top: 0;
  line-height: 1.2rem;
}
.component-order-step.step-two .component-order-step-inner p:last-of-type {
  margin-bottom: 3rem;
}
.component-order-step.step-two .component-order-step-inner .choose-option-container {
  margin-bottom: 0;
}
.component-order-step.step-two .component-order-step-inner h5 {
  font-family: Gilroy-semibold, sans-serif;
}/*# sourceMappingURL=OrderStepTwo.css.map */