.section-signup-form .form-container {
  border: 1px solid rgb(0, 224, 255);
  border-radius: 0.6rem;
  padding: 5rem;
  padding-bottom: 3rem;
  min-height: 35rem;
  width: 100%;
  background-color: rgba(19, 18, 18, 0.8);
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
}
.section-signup-form .form-container .form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.section-signup-form .form-container .form-wrapper .turnstile-container {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.section-signup-form .form-container .form-wrapper .turnstile-container iframe {
  border-radius: 7px !important;
  border: 1px solid rgb(0, 224, 255) !important;
}
.section-signup-form .form-container .form-wrapper .form.signup-form {
  width: 100%;
}
.section-signup-form .form-container .form-wrapper .form.signup-form h1 {
  font-size: 3rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3.1rem;
}
.section-signup-form .form-container .form-wrapper .form.signup-form .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.section-signup-form .form-container .form-wrapper .form.signup-form .input-group .password-toggle {
  display: flex;
  height: auto;
  background-color: rgb(0, 224, 255);
  flex: 0.05;
  justify-content: center;
}
.section-signup-form .form-container .form-wrapper .form.signup-form .input-group .password-toggle img {
  max-width: 100%;
  width: 1.5rem;
  margin: 0;
  padding: 0;
}
.section-signup-form .form-container .form-wrapper .form.signup-form .button {
  font-size: 1.1rem;
}
.section-signup-form .form-container .form-wrapper .form.signup-form .form-group {
  margin-bottom: 1.5rem;
}
@media (max-width: 991px) {
  .section-signup-form .form-container {
    padding: 1rem;
  }
  .section-signup-form .form-container .form-wrapper .form.signup-form h1 {
    font-size: 2.1rem;
  }
}
.section-signup-form .have-account-text-wrapper {
  margin-top: 0.3rem;
  width: 100%;
  height: 5%;
  text-align: center;
}
.section-signup-form .have-account-text-wrapper p {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
}
.section-signup-form .have-account-text-wrapper a {
  text-decoration: none;
}
.section-signup-form .have-account-text-wrapper a .highlighted {
  color: rgb(0, 224, 255);
  font-weight: bold;
}
.section-signup-form .choose-option-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.section-signup-form .choose-option-container .button {
  margin-bottom: 1rem;
}
.section-signup-form .choose-option-container:last-of-type {
  margin-bottom: 1.3rem;
}
.section-signup-form .choose-option-container p {
  margin-bottom: 1rem;
  color: white;
}
.section-signup-form .choose-option-container .options-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  flex-wrap: wrap;
  transition: all 0.3s ease;
}
.section-signup-form .choose-option-container .options-container .option-wrapper * {
  transition: all 0.3s ease;
}
.section-signup-form .choose-option-container .options-container .option-wrapper .dot {
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  background: rgb(150, 151, 151);
  border-radius: 4px;
  position: relative;
  margin-right: 0.7rem;
  z-index: 1005;
}
.section-signup-form .choose-option-container .options-container .option-wrapper .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: rgb(0, 0, 0);
  border-radius: 4px;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
.section-signup-form .choose-option-container .options-container .option-wrapper .option {
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 13rem;
}
.section-signup-form .choose-option-container .options-container .option-wrapper .option.preferable {
  border: 2px solid rgb(0, 224, 255);
}
.section-signup-form .choose-option-container .options-container .option-wrapper .option.preferable span {
  color: rgb(0, 224, 255);
}
.section-signup-form .choose-option-container .options-container .option-wrapper .option span {
  font-size: 0.8rem;
  color: rgb(150, 151, 151);
  z-index: 1;
}
.section-signup-form .choose-option-container .options-container .option-wrapper .option span a {
  text-decoration: none;
  color: rgb(150, 151, 151);
}
.section-signup-form .choose-option-container .options-container .option-wrapper input[type=radio],
.section-signup-form .choose-option-container .options-container .option-wrapper input[type=checkbox] {
  display: none;
  transition: all 0.3s ease-in-out;
}
.section-signup-form .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option {
  color: rgb(0, 224, 255);
}
.section-signup-form .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot {
  background: #fff;
}
.section-signup-form .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot::before {
  opacity: 1;
  transform: scale(1);
}
.section-signup-form .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option span {
  color: #FFFFFF;
}
.section-signup-form .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option span a {
  color: #FFFFFF;
}
.section-signup-form .spinner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}/*# sourceMappingURL=SignupForm.css.map */