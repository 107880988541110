.component-time-holder {
  display: inline-block;
  position: relative;
  border: 1px solid rgb(0, 224, 255);
  padding: 1rem;
  height: 4rem;
  width: 4.5rem;
  border-radius: 7px;
}
.component-time-holder:hover {
  border: 1px solid #FFFFFF;
  cursor: pointer;
}
.component-time-holder-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.component-time-holder-inner p {
  text-align: center;
  font-size: 1rem;
  color: #FFFFFF;
  line-height: 1.1rem;
  font-family: Gilroy-semibold, sans-serif;
  margin: 0;
  padding: 0;
}
.component-time-holder.clicked {
  background-color: rgb(0, 224, 255);
}
.component-time-holder.clicked .component-time-holder-inner p {
  color: #000000;
}
.component-time-holder.clicked:hover {
  border: 1px solid rgb(0, 224, 255);
}/*# sourceMappingURL=TimeHolder.css.map */