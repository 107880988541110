.section-what-we-improve {
    position: relative;

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .component-polaroid {
        margin-bottom: 2rem;
    }

    .polaroid-wrapper {
        .component-polaroid {
            img {
                width: 15rem;
                @media (min-width: 768px) and (max-width: 991px) {
                    width: 17rem;
                }
            }
        }

        @media (max-width: 767px) {
            padding: 0 3rem;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            padding: 0 6rem;
        }
    }

    .polaroids-container {
        margin-top: 5rem;

        @media (max-width: 575px) {
            margin-top: 3rem;
        }

        @media (min-width: 576px) and (max-width: 767px) {
            margin-top: 3rem;
        }
    }

    #proserwis-header {

        animation: shine 3s infinite linear;
        font-size: 5rem;
        background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;

        @media (max-width: 575px) {
            font-size: 3rem;
        }

        @media (min-width: 576px) and (max-width: 767px) {
            font-size: 4rem;
        }

    }

    @keyframes shine {
        0% {
            background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
            background-position: 0px;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        25% {
            background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
            background-position: 170px;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        50% {
            background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
            background-position: 170px;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        75% {
            background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
            background-position: 464px;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        100% {
            background: linear-gradient(190deg, rgba(255, 255, 255, 1) 48%, rgba(143, 241, 255, 1) 60%);
            background-position: 464px;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }
    }

}