.component-polaroid {
  display: block;
  position: relative;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.4));
  border: 1px solid rgb(0, 224, 255);
  width: 100%;
  background-color: rgba(19, 19, 19, 0.8);
  border-radius: 1rem;
  height: 27rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.component-polaroid:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
  z-index: 2;
}
.component-polaroid:hover .polaroid-title-container h4 {
  color: rgb(0, 224, 255);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.component-polaroid-inner {
  padding: 2rem;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}
.component-polaroid-inner .polaroid-icon-container {
  display: flex;
  width: 100%;
  text-align: center;
  height: 12rem;
  align-items: center;
  justify-content: center;
  margin-top: -0.4rem;
}
.component-polaroid-inner .polaroid-icon-container img {
  text-align: center;
  margin: 0;
  padding: 0;
  align-self: center;
}
.component-polaroid-inner .polaroid-title-container {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-family: Gilroy-bold, sans-serif;
  margin-top: -0.3rem;
  margin-bottom: 1.3rem;
}
.component-polaroid-inner .polaroid-title-container h4 {
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}
.component-polaroid-inner .polaroid-text-container {
  width: 100%;
  text-align: center;
  font-family: Gilroy-bold, sans-serif;
  background: linear-gradient(25deg, rgb(131, 127, 127) 67%, rgb(73, 72, 72) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}/*# sourceMappingURL=Polaroid.css.map */