.login {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .section-login-form {
        padding: 0;
        height: 100%;

        @media (min-width: 2048px) and (max-width: 3839px) {
            padding: 8rem 4rem;
        }

        @media (min-width:3840px) {
            padding: 8rem 4rem;
        }
    }
}