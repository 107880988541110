.component-top-navigation {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.component-top-navigation.absolute {
  position: absolute;
  z-index: 1005;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.component-top-navigation.black {
  background-color: rgb(0, 0, 0);
}
.component-top-navigation .col.center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.component-top-navigation .col.center.end {
  justify-content: flex-end;
}
.component-top-navigation-inner {
  font-family: Gilroy-semibold, sans-serif;
  letter-spacing: 1px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
}
.component-top-navigation-inner .list-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  background-color: transparent;
  float: right;
}
.component-top-navigation-inner .list-container ul li {
  display: inline-block;
  margin-left: 1.7rem;
  margin-right: 1.7rem;
  font-size: 1.1rem;
  text-align: center;
}
.component-top-navigation-inner .list-container ul li.nav-highlighted {
  animation: glow 1s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(28, 179, 206, 0.5), 0 0 20px rgba(35, 180, 221, 0.5), 0 0 25px rgba(21, 200, 232, 0.5), 0 0 30px rgba(6, 193, 245, 0.5), 0 0 35px rgba(11, 219, 247, 0.5);
  }
  to {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(0, 208, 255, 0.5), 0 0 20px rgba(77, 234, 255, 0.5), 0 0 25px rgba(39, 223, 255, 0.5), 0 0 30px rgba(0, 208, 236, 0.5), 0 0 35px rgba(0, 183, 255, 0.5), 0 0 40px rgba(12, 194, 255, 0.5);
  }
}
.component-top-navigation-inner .list-container ul li:last-of-type {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: 1rem;
}
.component-top-navigation-inner .list-container ul li a {
  text-decoration: none;
  color: #FFFFFF;
  font-family: Gilroy-semibold, sans-serif;
}
.component-top-navigation-inner .list-container ul li a:hover {
  text-decoration: none;
  color: rgb(0, 224, 255);
  transition: color 0.1s ease-in-out;
}
.component-top-navigation-inner .list-container ul li.account-menu {
  margin-right: 0rem;
}
.component-top-navigation-inner .list-container ul li.account-menu a:hover {
  text-decoration: none;
  color: rgb(0, 224, 255);
  transition: inherit;
}
.component-top-navigation-inner .list-container ul .button {
  font-size: 1.1rem;
  padding: 0.5rem 1.2rem;
  color: black;
  letter-spacing: normal;
  font-family: Gilroy-semibold, sans-serif;
}
.component-top-navigation-inner .list-container ul .button.try {
  background-color: rgb(0, 224, 255);
  color: black;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.component-top-navigation-inner .list-container ul .button.try.black {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(0, 224, 255);
  border: 1px solid rgba(0, 224, 255, 0.5);
}
.component-top-navigation-inner .list-container ul .button.try.black:hover {
  border: 1px solid rgb(198, 196, 196);
  color: rgb(255, 255, 255);
}
.component-top-navigation-inner .list-container ul .button.try:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(0, 224, 255);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
  border: 1px solid rgb(0, 224, 255);
}
.component-top-navigation-inner .list-container .dropdown-menu-container {
  position: absolute;
  margin: -1.7rem 0 0 -1.5rem;
  height: 120px;
  width: 190px;
}
.component-top-navigation-inner .list-container .nav-dropdown {
  border: white;
}
@keyframes slideDown {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.component-top-navigation-inner .list-container .nav-dropdown .nav-link {
  position: relative;
  z-index: 1002;
}
.component-top-navigation-inner .list-container .nav-dropdown .dropdown-menu {
  animation: slideDown 0.2s ease-in-out;
  margin-top: 0.2rem;
}
.component-top-navigation-inner .list-container .nav-dropdown .dropdown-menu.show {
  background-color: rgb(19, 19, 19);
  border: 1px solid #FFFFFF;
  border-radius: 7px;
}
.component-top-navigation-inner .list-container .nav-dropdown .dropdown-menu .dropdown-item {
  color: #FFFFFF;
  font-family: Gilroy-semibold, sans-serif;
  font-size: 0.9rem;
}
.component-top-navigation-inner .list-container .nav-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(0, 224, 255);
  color: black;
}
@media (max-width: 991px) {
  .component-top-navigation-inner .list-container {
    display: none;
    height: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .component-top-navigation-inner .list-container ul li {
    font-size: 1.05rem;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
  }
  .component-top-navigation-inner .list-container ul li a {
    font-size: 1.05rem;
  }
  .component-top-navigation-inner .list-container ul li .button {
    font-size: 1.05rem;
  }
}
.component-top-navigation-inner .logotype-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 10rem;
}
.component-top-navigation-inner .logotype-wrapper a {
  width: 100%;
  height: auto;
}
@media (max-width: 575px) {
  .component-top-navigation-inner {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .component-top-navigation-inner {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}/*# sourceMappingURL=TopNavigation.css.map */