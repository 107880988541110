.section-products {
    padding-top: 2rem;

    .promo-products-text {
        margin-top: 7rem;

    }

    .promo-products-container,
    .main-products-container {
        height: auto;
        padding-top: 2.5rem;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        margin: 0;
        flex-grow: 1;

        .shop-product-wrapper {
            display: flex;
            flex: 1;
        }

        .component-shop-product {
            flex: 1;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            margin-top: -4rem;
        }

        @media (max-width: 767px) {
            .shop-product-wrapper {
                margin-top: 4rem;

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            .shop-product-wrapper {
                margin-top: 4rem;

            }
        }

        @media (min-width: 1200px) {}



    }

}