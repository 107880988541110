.component-step {
    &.step-two {
       

        .component-step-inner {
            p {
                text-align: center;
            }

            .options-brief-container {
                display: flex;
                flex-direction: row;
                margin-top: 1.5rem;
                margin-bottom: 1rem;

                p {
                    text-align: left;
                }

                .option-wrapper {

                    &.advanced {
                        color: rgb(0, 224, 255);
                    }

                    &.base {
                        color: rgb(150 151 151);
                    }

                    ul {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                }

                @media (max-width: 767px) {
                    flex-direction: column;
                }

            }
        }

        .component-simple-container {
            &:not(:last-of-type) {
                margin-right: 1rem;

                @media (max-width: 767px) {
                    margin-bottom: 1rem;
                    margin-right: 0;
                }
            }

            padding: 1rem;
        }
    }
}