.section-services-description {

    .container {

        .product-box-description-container {
            margin-bottom: 4rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }


}