.top-banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;


}

.top-banner-personal {
    flex: 2;

    @media (max-width: 991px) {
        flex: 1;
    }
}

.top-banner-graphics {
    flex: 1;
    width: 100%;
    height: 100%;

    @media (max-width: 991px) {
        display: none;
    }
}