.component-userdata-form {
    position: relative;
    display: block;

    &-inner {

        h1 {
            font-size: 3rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: center;
            margin-bottom: 2rem;
            line-height: 3.1rem;
        }

        .form-label {
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: left;
            margin-bottom: 0.5rem;

        }

        .form-select.phone-select {
            display: flex;
            height: auto;
            background-color: rgb(0, 224, 255);
            flex: 0.2;
            justify-content: center;
        }

        .phone-input {
            flex: 0.8;
        }

        .button {
            margin-top: 2rem;
            font-size: 1rem;
            padding: 0.6rem 2.1rem;
            font-family: Gilroy-semibold, sans-serif;
        }

        .form-userdata-group,
        .form-clientdata-group {
            margin-bottom: 0.7rem;

            &.alternative {

                .form-label {
                    font-family: Gilroy-regular, sans-serif;
                    color: rgba(0, 224, 255, 0.8);
                }
            }
        }



        .plaintext {
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 0, 0, 1);
            background-color: rgba(255, 255, 255, 0.7);
            border: rgba(0, 0, 0, 0.7);
        }

        .form-control {
            filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

            &:disabled {
                background-color: rgba(255, 255, 255, 0.7);
            }

            &.loading {
                color: transparent;

                &::placeholder {
                    color: transparent;
                    background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgb(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
                    border-radius: 7px;
                    background-size: 200% 100%;
                    animation: 0.5s loading linear infinite;
                }

            }

        }


    }

}