.component-my-service-bar-sceletor {
    display: block;
    position: relative;
    height: 8rem;
    width: 100%;
    background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
    border-radius: 7px;
    background-size: 200% 100%;
    animation: 0.5s loading linear infinite;

}