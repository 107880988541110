.section-new-order {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    min-height: 45rem;
    font-family: Gilroy-semibold, sans-serif;
    padding-top: 2rem;

    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 45rem;
        width: 100%;

        .spinner-container {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            color: #FFFFFF;
            font-size: 1rem;
            line-height: 1.7rem;
        }

        h2 {
            font-size: 3rem;
            background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-family: Gilroy-bold, sans-serif;
        }

        h4 {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 2rem;
            line-height: 2.2rem;
        }

        h5 {
            margin-top: 1rem;
            background: linear-gradient(25deg, rgba(198, 196, 196, 1) 70%, rgba(255, 255, 255, 1) 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 1rem;
            line-height: 1.2rem;
            margin: 0;
        }

        .header-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: 0.2;
            text-align: center;

            .component-text {
                padding-bottom: 0;
                margin-bottom: 3rem;
            }

            .counter-wrapper {
                width: 100%;

                .alert {
                    padding: 0.5rem;
                    margin-top: 1rem;
                }


            }
        }

        .step-view-container {
            flex: 7;

            .step-view-state {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                h4 {
                    margin-top: -5rem;
                }

                &.center {
                    align-items: center;
                }
            }

            .order-step {
                width: 100%;
                height: 100%;
                padding: 1rem;

            }
        }


        .bottom-nav-container {
            display: flex;
            flex-direction: row;
            flex: 0.5;
            justify-content: flex-end;
            align-items: flex-end;
            flex-wrap: wrap;
            text-align: right;
            margin-top: 1rem;

            .bottom-nav-wrapper {
                .button {
                    &:first-of-type {
                        margin-right: 1rem;
                    }
                }
            }

        }

        .choose-option-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;

            .button {
                margin-bottom: 1rem;
            }

            &:last-of-type {
                margin-bottom: 1.3rem;
            }

            p {
                margin-bottom: 1rem;
                color: white;
            }

            .options-container {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 7px;
                flex-wrap: wrap;
                box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
                transition: all 0.3s ease;

                .option-wrapper {
                    * {
                        transition: all 0.3s ease;
                    }

                    .dot {
                        height: 20px;
                        width: 20px;
                        max-height: 20px;
                        max-width: 20px;
                        background: #d9d9d9;
                        border-radius: 4px;
                        position: relative;
                        margin-right: 1rem;

                        &::before {
                            position: absolute;
                            content: "";
                            top: 4px;
                            left: 4px;
                            width: 12px;
                            height: 12px;
                            background: rgb(0, 0, 0);
                            border-radius: 4px;
                            opacity: 0;
                            transform: scale(1.5);
                            transition: all 0.3s ease;
                        }
                    }

                    .option {

                        background: transparent;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 7px;
                        cursor: pointer;
                        padding: 0.8rem 1.7rem;
                        border: 2px solid rgb(150, 151, 151);
                        transition: all 0.3s ease-in-out;
                        min-width: 13rem;

                        &.preferable {

                            border: 2px solid rgb(0, 224, 255);

                            span {
                                color: rgb(0, 224, 255);
                            }
                        }

                        span {
                            font-size: 1rem;
                            color: rgb(150, 151, 151);

                        }

                        &:hover {
                            border: 2px solid rgb(0, 224, 255);

                            span {
                                color: rgb(0, 224, 255);
                            }
                        }
                    }

                    input[type="radio"],
                    input[type="checkbox"] {
                        display: none;
                        transition: all 0.3s ease-in-out;
                    }

                    input:checked:checked~.option {
                        border-color: rgb(0, 224, 255);
                        background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
                        transition: all 0.3s ease-in-out;
                    }

                    input:checked:checked~.option .dot {
                        background: #fff;
                    }

                    input:checked:checked~.option .dot::before {
                        opacity: 1;
                        transform: scale(1);
                    }

                    input:checked:checked~.option span {
                        color: rgb(0, 0, 0)
                    }

                }

            }



        }
    }
}