.component-order-step {
    &.payment-summary {
        min-height: 25rem;
        height: auto;
        text-align: center;

        &.center {
            .component-order-step-inner {
                justify-content: center;
            }
        }
        .component-order-step-inner {
            justify-content: flex-start;
            align-items: center;

            p {
                font-family: Gilroy-semibold, sans-serif;
                margin-bottom: 0.8rem;
                margin-top: 0;
                line-height: 1.2rem;
                font-size: 1rem;
                color: #FFFFFF;

                img {
                    display: inline-block;
                }

                &:last-of-type {
                    margin-bottom: 3rem;
                }
            }

            .choose-option-container {
                margin-bottom: 1rem;
            }

            h5 {
                font-family: Gilroy-semibold, sans-serif;
            }
        }

        .payment-failure-container {
            h1 {
                color: #ea868f;
            }
        }

        .payment-success-container {
            h1 {
                color: #75b798;
            }
        }
    }

}