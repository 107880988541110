button.button,
a.button,
label.button,
input.button {
  display: inline-block;
  padding: 0.8rem 2.1rem;
  font-family: Gilroy-semibold, sans-serif;
  text-decoration: none;
  border-radius: 7px;
  font-size: calc(1.4rem - 2px);
  text-align: center;
  opacity: 1;
  border: 1px solid transparent;
}
button.button.status-of-service,
a.button.status-of-service,
label.button.status-of-service,
input.button.status-of-service {
  padding: 0.7rem;
  border-radius: 7px;
  min-width: 7rem;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
@media (min-width: 768px) {
  button.button.status-of-service:hover,
  a.button.status-of-service:hover,
  label.button.status-of-service:hover,
  input.button.status-of-service:hover {
    color: rgb(0, 224, 255);
  }
}
button.button.status-of-service.prepare,
a.button.status-of-service.prepare,
label.button.status-of-service.prepare,
input.button.status-of-service.prepare {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 224, 255);
}
@media (min-width: 768px) {
  button.button.status-of-service.prepare:hover,
  a.button.status-of-service.prepare:hover,
  label.button.status-of-service.prepare:hover,
  input.button.status-of-service.prepare:hover {
    color: rgb(0, 224, 255);
  }
}
button.button.status-of-service.awaiting,
a.button.status-of-service.awaiting,
label.button.status-of-service.awaiting,
input.button.status-of-service.awaiting {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffff00;
  border: 1px solid #ffff00;
  cursor: default;
}
button.button.status-of-service.completed,
a.button.status-of-service.completed,
label.button.status-of-service.completed,
input.button.status-of-service.completed {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255, 1, 128);
  border: 1px solid rgb(255, 1, 128);
  cursor: default;
}
button.button.status-of-service.ready,
a.button.status-of-service.ready,
label.button.status-of-service.ready,
input.button.status-of-service.ready {
  background-color: rgba(0, 0, 0, 0.2);
  color: #00ff00;
  border: 1px solid #00ff00;
  cursor: default;
}
@media (max-width: 991px) {
  button.button.status-of-service,
  a.button.status-of-service,
  label.button.status-of-service,
  input.button.status-of-service {
    font-size: 0.9rem;
  }
}
button.button.status-of-invoice-sent,
a.button.status-of-invoice-sent,
label.button.status-of-invoice-sent,
input.button.status-of-invoice-sent {
  padding: 0.7rem;
  border-radius: 7px;
  min-width: 7rem;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
button.button.status-of-invoice-sent.false,
a.button.status-of-invoice-sent.false,
label.button.status-of-invoice-sent.false,
input.button.status-of-invoice-sent.false {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 224, 255);
}
@media (min-width: 768px) {
  button.button.status-of-invoice-sent.false:hover,
  a.button.status-of-invoice-sent.false:hover,
  label.button.status-of-invoice-sent.false:hover,
  input.button.status-of-invoice-sent.false:hover {
    color: rgb(0, 224, 255);
  }
}
button.button.status-of-invoice-sent.true,
a.button.status-of-invoice-sent.true,
label.button.status-of-invoice-sent.true,
input.button.status-of-invoice-sent.true {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255, 1, 128);
  border: 1px solid rgb(255, 1, 128);
  cursor: default;
}
@media (max-width: 991px) {
  button.button.status-of-invoice-sent,
  a.button.status-of-invoice-sent,
  label.button.status-of-invoice-sent,
  input.button.status-of-invoice-sent {
    padding: 0.4rem 0.2rem;
    min-width: 5rem;
  }
}
button.button.try,
a.button.try,
label.button.try,
input.button.try {
  background-color: rgb(0, 224, 255);
  color: black;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}
button.button.try.black,
a.button.try.black,
label.button.try.black,
input.button.try.black {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(0, 224, 255);
  border: 1px solid rgba(0, 224, 255, 0.5);
}
@media (min-width: 768px) {
  button.button.try.black:hover,
  a.button.try.black:hover,
  label.button.try.black:hover,
  input.button.try.black:hover {
    border: 1px solid rgb(198, 196, 196);
    color: rgb(255, 255, 255);
  }
}
button.button.try.gray,
a.button.try.gray,
label.button.try.gray,
input.button.try.gray {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(198, 196, 196);
  border: 1px solid rgb(198, 196, 196);
}
@media (min-width: 768px) {
  button.button.try.gray:hover,
  a.button.try.gray:hover,
  label.button.try.gray:hover,
  input.button.try.gray:hover {
    border: 1px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
  }
}
@media (min-width: 768px) {
  button.button.try:hover,
  a.button.try:hover,
  label.button.try:hover,
  input.button.try:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgb(0, 224, 255);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
    border: 1px solid rgb(0, 224, 255);
  }
}
button.button.try.hover2,
a.button.try.hover2,
label.button.try.hover2,
input.button.try.hover2 {
  background-color: rgb(0, 224, 255);
  color: black;
}
button.button.try.hover2:disabled,
a.button.try.hover2:disabled,
label.button.try.hover2:disabled,
input.button.try.hover2:disabled {
  background-color: rgb(150, 151, 151);
  border-color: rgb(150, 151, 151);
  cursor: default;
  pointer-events: none;
}
@media (min-width: 768px) {
  button.button.try.hover2:hover,
  a.button.try.hover2:hover,
  label.button.try.hover2:hover,
  input.button.try.hover2:hover {
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
    filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
  }
}
@media (min-width: 768px) {
  button.button.try.nohover:hover,
  a.button.try.nohover:hover,
  label.button.try.nohover:hover,
  input.button.try.nohover:hover {
    background-color: rgb(0, 224, 255);
    color: black;
  }
}
button.button.small,
a.button.small,
label.button.small,
input.button.small {
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  line-height: 1rem;
}
button.button.small-med,
a.button.small-med,
label.button.small-med,
input.button.small-med {
  padding: 0.7rem 1.1rem;
  font-size: 1rem;
  line-height: 1rem;
}
button.button.medium,
a.button.medium,
label.button.medium,
input.button.medium {
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  line-height: 1.3rem;
}
button.button.radio, button.button.checkbox,
a.button.radio,
a.button.checkbox,
label.button.radio,
label.button.checkbox,
input.button.radio,
input.button.checkbox {
  padding: 0.8rem 1.7rem;
  font-size: 1rem;
  min-width: 13rem;
  border: 2px solid rgb(0, 224, 255);
}
@media (min-width: 768px) {
  button.button.radio:hover, button.button.checkbox:hover,
  a.button.radio:hover,
  a.button.checkbox:hover,
  label.button.radio:hover,
  label.button.checkbox:hover,
  input.button.radio:hover,
  input.button.checkbox:hover {
    border: 2px solid rgb(0, 224, 255);
  }
}
button.button.simple,
a.button.simple,
label.button.simple,
input.button.simple {
  padding: 0.6rem 0.6rem;
  font-size: 1rem;
  background-color: rgb(0, 224, 255);
  color: black;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}
@media (min-width: 768px) {
  button.button.simple:hover,
  a.button.simple:hover,
  label.button.simple:hover,
  input.button.simple:hover {
    background-color: rgb(0, 224, 255);
    color: black;
  }
}
@media (max-width: 991px) {
  button.button.simple,
  a.button.simple,
  label.button.simple,
  input.button.simple {
    font-size: 0.9rem;
  }
}
button.button:disabled,
a.button:disabled,
label.button:disabled,
input.button:disabled {
  background-color: rgb(150, 151, 151);
  border-color: rgb(150, 151, 151);
  cursor: default;
  pointer-events: none;
}
@media (min-width: 768px) {
  button.button:disabled:hover,
  a.button:disabled:hover,
  label.button:disabled:hover,
  input.button:disabled:hover {
    background-color: rgb(150, 151, 151);
    border-color: rgb(150, 151, 151);
    color: rgb(0, 0, 0);
  }
}
button.button.disabled,
a.button.disabled,
label.button.disabled,
input.button.disabled {
  background-color: rgb(150, 151, 151);
  border-color: rgb(150, 151, 151);
  cursor: default;
  pointer-events: none;
}
@media (min-width: 768px) {
  button.button.disabled:hover,
  a.button.disabled:hover,
  label.button.disabled:hover,
  input.button.disabled:hover {
    background-color: rgb(150, 151, 151);
    border-color: rgb(150, 151, 151);
    color: rgb(0, 0, 0);
  }
}
@media (max-width: 575px) {
  button.button,
  a.button,
  label.button,
  input.button {
    font-size: 1rem;
    padding: 0.56rem 1.47rem;
  }
  button.button.medium,
  a.button.medium,
  label.button.medium,
  input.button.medium {
    font-size: 0.9rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  button.button,
  a.button,
  label.button,
  input.button {
    font-size: 1rem;
    padding: 0.64rem 1.68rem;
  }
  button.button.medium,
  a.button.medium,
  label.button.medium,
  input.button.medium {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  button.button,
  a.button,
  label.button,
  input.button {
    font-size: 1.1rem;
  }
}/*# sourceMappingURL=Button.css.map */