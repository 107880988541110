.menu-wrapper {
    .language-selector {
        margin-left: 8px;
    }

    .bm-burger-button {

        opacity: 1;
        display: block;
        padding: 1rem 0 1rem 1rem;

        @media (min-width: 992px) {
            display: none;
        }
    }

    .social-info-container {
        p {
            font-size: 0.8rem;
            margin: 0;
            padding: 0;

            &:last-of-type {
                margin-bottom: 0.5rem;
            }
        }

        a {
            display: block;
        }
    }

    .bm-icon {
        width: 50px;
        height: 50px;
    }

    /* Position and sizing of burger button */

    .bm-burger-button-custom {
        color: rgb(0, 224, 255);
        position: relative;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #3d3e42;

        :hover {
            background: white;
        }
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: rgba(255, 255, 255, 1);
    }


    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        margin-right: 1rem;
        margin-top: 1rem;
        height: 2rem;
        width: 2rem;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /*
      Sidebar wrapper styles
      Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
      */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
        left: 0px;
        top: 0px;

    }

    /* General sidebar styles */
    .bm-menu {
        background: rgba(0, 0, 0, 0.98);
        padding: 1.2em 0.5em 0;
        font-size: 1.50rem;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
        font-family: Gilroy-regular, sans-serif;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
        padding: 0.5em;
        color: #b8b6b6;
        text-decoration: none;

        a {
            text-decoration: none;
        }

        &:hover {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }


    }

    .menu-buttons-container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        button {
            img {
                filter: invert(50%) brightness(90%);
            }
        }

        :not(:first-child) {
            margin-left: 1rem;
        }
    }

    .menu-item {
        font-family: Gilroy-regular, sans-serif;

        img {
            max-width: 100%;
            filter: invert(50%) brightness(90%);
        }

        &-white {
            font-family: Gilroy-semibold, sans-serif;
            background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
        }

        &-logout {
            font-family: Gilroy-regular, sans-serif;
            // margin-top: 5rem;
        }


    }


    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        top: 0px;
        left: 0px;
    }
}