.section-services-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.section-implementation {
    margin-top: 4rem;
    padding-top: 2rem;
    .component-text{
        :first-of-type {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

.section-services-description {
    padding-top:2rem;
}