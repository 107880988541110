.component-settings-function-bar {
  display: block;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border-radius: 7px;
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
  background-color: rgba(19, 18, 18, 0.8);
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.component-settings-function-bar:hover {
  box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgb(0, 224, 255) 0px 0px 2px 2px;
  cursor: pointer;
}
.component-settings-function-bar:hover h1 {
  font-size: 1.2rem;
}
.component-settings-function-bar-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem;
}
.component-settings-function-bar-inner h1 {
  font-family: "Gilroy-bold", sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 0;
  margin: 0;
  color: rgb(0, 224, 255);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}/*# sourceMappingURL=SettingsFunctionBar.css.map */