footer {
    display: block;
    position: relative;
    background-color: rgba(3, 13, 20, .6);
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.70);
    flex-shrink: 0;

    .footer-wrapper {
        padding: 3rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.15);



        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 575px) {
                flex-direction: column;
            }

            .col {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                overflow: hidden;

                p,
                a {
                    text-align: center;
                    text-decoration: none;
                    margin: 0 1rem;
                    padding: 0;
                    font-size: 1rem;
                    color: #adb5bd;
                    font-family: Gilroy-regular, sans-serif;
                    line-height: 1.1rem;

                    @media (max-width: 1399px) {
                        margin: 0 0.65rem;
                    }
                }



                &.left {
                    justify-content: flex-start;

                    @media (max-width: 991px) {
                        justify-content: center;
                    }



                    p,
                    a {
                        &:first-child {
                            margin-left: 0;
                            margin-right: 1rem;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }

                &.right {
                    justify-content: flex-end;

                    @media (max-width: 991px) {
                        justify-content: center;
                    }

                    p,
                    a {
                        &:last-child {
                            margin-left: 1rem;
                            margin-right: 0rem;
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                    }

                    .icons-holder {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        margin-left: 1rem;

                        a {
                            margin-left: 5px;
                            margin-right: 0;
                            width: 24px;
                            height: 24px;
                            vertical-align: middle;
      
                            img {
                                width: 100%;
                                height: 100%;
                            }

                            &:first-child {
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }

                        @media (max-width: 575px) {
                            margin-left: 0;
                            a {
                                &:nth-child(n) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 575px) {
                    flex-direction: column;

                    p,
                    a {
                        &:nth-child(n) {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }


        .logotype-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3rem;
            width: 9rem;

            a {
                width: 100%;
                height: auto;
            }
        }
    }

}