.component-my-service-bar {
    display: block;
    position: relative;
    height: 8rem;
    width: 100%;
    border-radius: 7px;
    box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
    background-color: rgba(19, 18, 18, 0.8);
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    &-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;


        p {
            font-family: Gilroy-semibold, sans-serif;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1rem;

            @media (max-width: 991px) {
                font-size: 0.9rem;
            }
        }

        .date-of-service-container {

            position: relative;
            height: 100%;
            border-right: 1px solid #30363d;
            flex: 1;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;

            .date-of-service-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .date-of-service-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: 0.8;
                position: relative;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);

                }

                p {
                    padding-bottom: 1rem;
                }
            }
        }

        .name-of-service-container {
            height: 100%;
            border-right: 1px solid #30363d;
            flex: 1;
            display: flex;
            flex-direction: column;

            .name-of-service-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .name-of-service-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: rgba(0, 224, 255, 1);
                flex: 0.8;


                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: #818080;
                    margin-bottom: 0.4rem;

                }

                p {
                    padding-bottom: 1rem;
                }
            }
        }

        .status-of-service-container {
            height: 100%;
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;

            .status-of-service-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .status-of-service-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: 0.8;

                .button {
                    margin-bottom: 1rem;
                }
            }
        }

        .vertical-dots-wrapper {
            position: absolute;
            content: '';
            z-index: 2;
            width: 3rem;
            height: 3rem;
            bottom: 0;
            top: 10px;
            left: 0;
            margin-top: auto;
            margin-bottom: auto;
            background-image: url('../../../assets/img/icons/vertical_dots_gray.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            fill: red;

            @media (max-width: 991px) {
                bottom: 0;
                top: auto;
                right: 0;
                left: 0;
                height: 2rem;
                width: 2rem;
                margin: auto;
                background-image: url('../../../assets/img/icons/horizontal_dots_gray.svg');

            }

            &:hover,
            :focus,
            :active {
                filter: invert(55%) sepia(96%) saturate(957%) hue-rotate(148deg) brightness(107%) contrast(108%);
            }
        }

        .bar-collapse-menu-container {

            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            position: absolute;
            width: 100%;
            z-index: 1;
            right: 0;
            top: 10px;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;

        }

        .schedule-button-enter {
            opacity: 0;
            transform: scale(0.9);
        }

        .schedule-button-enter-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 300ms, transform 300ms;
        }

        .schedule-button-exit {
            opacity: 1;

        }

        .schedule-button-exit-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms, transform 300ms;
        }
    }

    @media (max-width: 575px) {
        height: 15rem;

        .component-my-service-bar-inner {
            height: 100%;
            flex-wrap: wrap;

            .date-of-service-container,
            .name-of-service-container {
                height: 50%;
                flex-basis: 50%;
                padding: 0.5rem;
                border-bottom: 1px solid #30363d;


                p {
                    margin: 0;
                    padding: 0;
                }
            }

            .status-of-service-container {
                height: 50%;
                flex-basis: 100%;
                box-sizing: border-box;
                padding: 10px;
            }
        }
    }
}