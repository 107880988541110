.component-contact-form {
    position: relative;
    display: block;

    &-inner {

        h1 {
            font-size: 3rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: center;
            margin-bottom: 2rem;
            line-height: 3.1rem;
        }

        .form-label {
            font-size: 1rem;
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 224, 255, 1);
            text-align: left;
            margin-bottom: 0.5rem;

        }



        .button {
            margin-top: 2rem;
            font-size: 1rem;
            padding: 0.6rem 2.1rem;
            font-family: Gilroy-semibold, sans-serif;
        }

        .form-contact-group {
            margin-bottom: 0.7rem;

            &.alternative {

                .form-label {
                    font-family: Gilroy-regular, sans-serif;
                    color: rgba(0, 224, 255, 0.8);
                }

                .form-control {
                    font-family: Gilroy-semibold, sans-serif;
                    color: rgba(0, 0, 0, 1);
                }
            }
        }

        .plaintext {
            font-family: Gilroy-semibold, sans-serif;
            color: rgba(0, 0, 0, 1);
            background-color: rgba(255, 255, 255, 0.7);
            border: rgba(0, 0, 0, 0.7);
        }

        .form-control {
            &:disabled {
                background-color: rgba(255, 255, 255, 0.7);
            }

            &.loading {
                color: transparent;
               
                &::placeholder {
                    color: transparent;
                    background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgb(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
                    border-radius: 7px;
                    background-size: 200% 100%;
                    animation: 0.5s loading linear infinite;
                }

            }
        }
    }

}