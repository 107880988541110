.language-selector {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.selected-language img {
  width: 20px;
  height: 15px;
}

.language-options {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.language-options div {
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.language-options div img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
}/*# sourceMappingURL=LanguageSelector.css.map */