.component-order-step {

    &.service-details {
        border: 1px solid rgba(0, 224, 255, 0.35);

        .component-order-step-inner {
            h3 {
                margin-bottom: 4rem;
            }

            .service-details-container {
                flex: 1;
                height: 100%;
                width: 100%;

                .service-details-wrapper {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    .product-name-price-container {
                        display: block;
                        position: relative;
                        margin-bottom: 4rem;

                        .product-name-price-wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;

                            p {
                                margin: 0;
                                color: rgb(0, 224, 255);
                                text-align: center;
                            }

                            h1 {
                                font-size: 2rem;
                                color: #FFF;
                                line-height: 2.8rem;
                                text-align: center;
                                flex-grow: 1;
                                margin: 0;

                                span {
                                    font-size: 1.2rem;
                                    line-height: 1.3rem;
                                    margin-left: 0.2rem;
                                }
                            }

                            .flex-wrapper {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                margin-right: 1.2rem;

                                &:last-of-type {
                                    margin: 0;
                                }

                                &:nth-of-type(odd) {
                                    p {

                                        color: #FFF;
                                    }

                                    h1 {
                                        color: rgb(0, 224, 255);
                                    }
                                }
                            }

                        }


                    }

                    .sponsor-slider-wrapper {
                        height: auto;
                        display: block;
                        overflow: hidden;
                        p {
                            color: rgb(150, 151, 151);
                            text-align: center;
                            line-height: 1.4rem;
                            font-family: Gilroy-regular, sans-serif;

                        }
                    }

                    .product-price-container {
                        p {
                            font-size: 2.5rem;
                            color: #FFF;
                            text-align: center;
                            font-family: Gilroy-bold, sans-serif;
                            line-height: 2.8rem;
                        }
                    }

                    .product-photo-wrapper {
                        width: 3rem;
                        height: 3rem;
                        text-align: center;
                        align-self: center;
                    }
                }
            }

            @media (max-width: 991px) {
                flex-direction: column;

                .payment-info-container {
                    margin-bottom: 2rem;

                    .payment-info-wrapper {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }
        }
    }

    &.payment {
        .component-order-step-inner {

            .component-product-payment {
                display: block;
                position: relative;
                height: 100%;

                &-inner {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    align-items: flex-start;
                    justify-content: center;



                    .payment-form-container {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }

        }
    }

}