.component-history-order-bar {
    display: block;
    position: relative;
    height: 9rem;
    width: 100%;
    border-radius: 7px;
    box-shadow: rgba(206, 210, 212, 0.7) 0px 0px 0px 0px, rgba(0, 224, 255, 0.5) 0px 0px 1px 1px;
    background-color: rgba(19, 18, 18, 0.8);
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));


    &-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        font-family: Gilroy-semibold, sans-serif;

        p {
            margin: 0;
            padding: 0;
        }

        .data-of-order-container {

            height: 100%;
            border-right: 1px solid #30363d;
            flex: 1;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;

            .data-of-order-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                h2 {

                    font-size: 1.2rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1.35rem;
                    font-family: Gilroy-Bold, sans-serif;
                    color: rgb(129, 128, 128);

                    @media (max-width: 991px) {
                        font-size: 1rem;

                    }

                    &:first-of-type {
                        margin-bottom: 0.7rem;
                    }

                    &:last-of-type {
                        margin-top: 0.7rem;
                    }
                }

                p {
                    color: rgb(129, 128, 128);
                    font-size: 0.8rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                }
            }
        }

        .name-of-service-container {
            height: 100%;
            border-right: 1px solid #30363d;
            flex: 1;
            display: flex;
            flex-direction: column;

            .name-of-service-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .name-of-service-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: rgba(0, 224, 255, 1);
                flex: 0.8;

                p {
                    padding-bottom: 1rem;

                    @media (max-width: 991px) {
                        font-size: 0.9rem;
                    }
                }

            }
        }

        .users-emails-container {
            height: 100%;
            border-right: 1px solid #30363d;
            flex: 1;
            display: flex;
            flex-direction: column;

            .users-emails-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .users-emails-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: rgb(129, 128, 128);
                font-size: 0.8rem;
                flex: 0.8;

                p {
                    padding-bottom: 1rem;

                    @media (max-width: 991px) {
                        max-width: 100%;
                    }
                }

            }
        }

        .ask-for-invoice-container {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .ask-for-invoice-description {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 0.2;

                h3 {
                    font-family: Gilroy-Regular, sans-serif;
                    font-size: 0.7rem;
                    margin: 0;
                    padding: 0;
                    line-height: 1rem;
                    color: rgb(129, 128, 128);
                    text-align: center;
                }
            }

            .ask-for-invoice-wrapper {

                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: 0.8;

                .button {
                    margin-bottom: 1rem;
                }
            }
        }

    }

    @media (max-width: 575px) {
        height: 18rem;

        .component-history-order-bar-inner {
            height: 100%;
            flex-wrap: wrap;

            .data-of-order-container,
            .name-of-service-container {
                height: 50%;
                flex-basis: 50%;
                /* Każdy z dwóch pierwszych kontenerów zajmuje 50% szerokości */
                padding: 0.5rem;
                border-bottom: 1px solid #30363d;

                p {
                    margin: 0;
                    padding: 0;
                }
            }

            .users-emails-container,
            .ask-for-invoice-container {
                height: 50%;
                flex-basis: auto;
                /* Kontener status-of-service-container zajmuje pełną szerokość w drugim wierszu */
                box-sizing: border-box;
                padding: 10px;
            }
        }
    }
}