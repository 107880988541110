.view-settings-container {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    font-family: Gilroy-semibold, sans-serif;

    .view-settings-wrapper {
        h2 {
            margin: 0;
        }
    }
}