.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: rgba(19, 19, 19, 0.8);
    border: 2px solid rgb(0, 224, 255);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &.visible {
        opacity: 1;
    }
}

.icon-style:hover {
    animation: none;
    border: 2px solid #fff;
    color: rgb(0, 224, 255);
    background-color: rgba(19, 19, 19, 0.2);
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}