.purchase {

    @keyframes glow-cyan {

        0%,
        100% {
            filter: drop-shadow(1px -3px 15px rgba(193, 255, 68, 0.2));
        }

        50% {
            filter: drop-shadow(1px -3px 15px rgba(193, 255, 68, 0.5));
        }
    }

}