.component-showcase {
  display: block;
  position: relative;
}
.component-showcase-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.component-showcase-inner .showcase-header-container {
  border: 1px solid rgba(0, 224, 255, 0.4);
  border-radius: 7px;
  padding: 0.5rem 3.5rem;
  min-width: 15rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.component-showcase-inner .showcase-header-container h4 {
  color: #FFFFFF;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 0;
  font-family: Gilroy-Bold, sans-serif;
}
.component-showcase-inner .showcase-text-container {
  color: #adb5bd;
  text-align: center;
  font-family: Gilroy-Light, sans-serif;
  font-size: 0.9rem;
}
.component-showcase-inner .showcase-text-container h5 {
  color: #FFFFFF;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 1rem 0 2rem 0;
  font-family: Gilroy-semibold, sans-serif;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.component-showcase-inner .showcase-text-container p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.6rem;
  font-family: Gilroy-semibold, sans-serif;
}/*# sourceMappingURL=Showcase.css.map */