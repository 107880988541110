.section-reviews {
    position: relative;

    filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.5));
    
    .text-reviews-container {

        text-align: left;

        h1 {
            color: #8ff1ff;
            font-size: 10rem;
            font-family: Gilroy-Bold, sans-serif;
            margin: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            padding: 0;
        }

        h2 {
            color: #FFFFFF;
            font-size: 4rem;
            font-family: Gilroy-Bold, sans-serif;
            margin-top: -1rem;
            margin-bottom: 2rem;

        }

        p {
            color: #adb5bd;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin: 0;
            font-family: Gilroy-Light, sans-serif;
            opacity: 0.5;
        }

        @media (max-width: 575px) {
            margin-bottom: 5rem;
            text-align: center;

            h1 {
                font-size: calc(0.7 * 10rem);
            }

            h2 {
                font-size: calc(0.7 * 4rem);
            }

            p {
                font-size: calc(0.7 * 1.5rem);
            }
        }

        @media (min-width: 576px) and (max-width: 767px) {
            margin-bottom: 5rem;
            text-align: center;

            h1 {
                font-size: calc(0.8 * 10rem);
            }

            h2 {
                font-size: calc(0.8 * 4rem);
            }

            p {
                font-size: calc(0.8 * 1.5rem);
            }

        }

        @media (min-width: 768px) and (max-width: 991px) {
            margin-bottom: 5rem;
            text-align: center;

            h1 {
                font-size: calc(0.9 * 10rem);
            }

            h2 {
                font-size: calc(0.9 * 4rem);
            }

            p {
                font-size: calc(0.9 * 1.5rem);
            }
        }

    }

    .other-reviews-wrapper {
        width: 100%;
        text-align: right;

        a {
            text-decoration: none;
            font-family: Gilroy-Light, sans-serif;
            color: rgba(255, 255, 255, 0.4);
            line-height: 1.5rem;
            font-size: 0.8rem;
            transition: color 0.5s;
            width: 100%;
            margin-left: -2rem;

            &:hover {
                transition: color 0.5s;
                color: rgba(255, 255, 255, 0.8);

            }
        }
    }
}