.section-use-potential {
    position: relative;

    .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;

    }

    .use-potential-text-wrapper {
        text-align: left;
    }
    .container {
        overflow: visible;
    }

    .monitor-container {
        display: none;

        @media (min-width: 1200px) {
            display: block;
        }
    }
}