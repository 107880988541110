.component-contact-form.contact-page {
  position: relative;
  display: block;
}
.component-contact-form.contact-page .component-contact-form-inner h1 {
  font-size: 3rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3.1rem;
}
.component-contact-form.contact-page .component-contact-form-inner .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.component-contact-form.contact-page .component-contact-form-inner .form-select.phone-select {
  display: flex;
  height: auto;
  background-color: rgb(0, 224, 255);
  flex: 0.2;
  justify-content: center;
}
.component-contact-form.contact-page .component-contact-form-inner .phone-input {
  flex: 0.8;
}
.component-contact-form.contact-page .component-contact-form-inner .button {
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.6rem 2.1rem;
  font-family: Gilroy-semibold, sans-serif;
}
.component-contact-form.contact-page .component-contact-form-inner .form-contact-group {
  margin-bottom: 0.7rem;
}
.component-contact-form.contact-page .component-contact-form-inner .form-contact-group.alternative .form-label {
  font-family: Gilroy-regular, sans-serif;
  color: rgba(0, 224, 255, 0.8);
}
.component-contact-form.contact-page .component-contact-form-inner .form-contact-group.alternative .form-control {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
}
.component-contact-form.contact-page .component-contact-form-inner .plaintext {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);
  border: rgba(0, 0, 0, 0.7);
}
.component-contact-form.contact-page .component-contact-form-inner .form-control:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .button {
  margin-bottom: 1rem;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container:last-of-type {
  margin-bottom: 1.3rem;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container p {
  margin-bottom: 1rem;
  color: white;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  flex-wrap: wrap;
  transition: all 0.3s ease;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper * {
  transition: all 0.3s ease;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .dot {
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  background: rgb(150, 151, 151);
  border-radius: 4px;
  position: relative;
  margin-right: 0.7rem;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: rgb(0, 0, 0);
  border-radius: 4px;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .option {
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 13rem;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .option.preferable {
  border: 2px solid rgb(0, 224, 255);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .option.preferable span {
  color: rgb(0, 224, 255);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .option span {
  font-size: 0.8rem;
  color: rgb(150, 151, 151);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper .option span a {
  text-decoration: none;
  color: rgb(150, 151, 151);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input[type=radio],
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input[type=checkbox] {
  display: none;
  transition: all 0.3s ease-in-out;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option {
  color: rgb(0, 224, 255);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot {
  background: #fff;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option .dot::before {
  opacity: 1;
  transform: scale(1);
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option span {
  color: #FFFFFF;
}
.component-contact-form.contact-page .component-contact-form-inner .choose-option-container .options-container .option-wrapper input:checked:checked ~ .option span a {
  color: #FFFFFF;
}/*# sourceMappingURL=ContactForm.css.map */