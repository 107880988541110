.section-full-profile {
  padding: 1rem 0;
  height: 100%;
}
@media (min-width: 2560px) {
  .section-full-profile {
    padding: 10rem;
  }
}
.section-full-profile .profile-container {
  position: relative;
  width: 100%;
  border-radius: 7px;
  box-shadow: rgba(206, 210, 212, 0.5) 0px 0px 0px 0px, rgba(0, 224, 255, 0.6) 0px 0px 1px 1px;
  background-image: url("./polygon-scatter-haikei.svg");
  overflow: hidden;
  height: 45rem;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
}
.section-full-profile .profile-wrapper {
  display: flex;
  flex-direction: column;
  height: 45rem;
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
}
.section-full-profile .profile-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35rem;
}
@media (max-width: 991px) {
  .section-full-profile .profile-main-container {
    flex-direction: column;
    height: 36rem;
  }
}
.section-full-profile .profile-main-block {
  display: block;
  position: relative;
  height: 100%;
  width: 85%;
}
@media (max-width: 991px) {
  .section-full-profile .profile-main-block {
    overflow-y: scroll;
    width: 100%;
  }
}
.section-full-profile .profile-side-block {
  display: block;
  position: relative;
  width: 15%;
  padding: 0;
  border-right: 1px solid #adb5bd;
}
@media (max-width: 991px) {
  .section-full-profile .profile-side-block {
    border-bottom: 1px solid #adb5bd;
    width: 100%;
  }
}
.section-full-profile .top-banner-container {
  width: 100%;
  border-bottom: 1px solid #adb5bd;
  height: 10rem;
}
@media (max-width: 991px) {
  .section-full-profile .top-banner-container {
    height: 9rem;
  }
}/*# sourceMappingURL=SectionFullProfile.css.map */