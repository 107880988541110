.component-order-step.payment-summary {
  min-height: 25rem;
  height: auto;
  text-align: center;
}
.component-order-step.payment-summary.center .component-order-step-inner {
  justify-content: center;
}
.component-order-step.payment-summary .component-order-step-inner {
  justify-content: flex-start;
  align-items: center;
}
.component-order-step.payment-summary .component-order-step-inner p {
  font-family: Gilroy-semibold, sans-serif;
  margin-bottom: 0.8rem;
  margin-top: 0;
  line-height: 1.2rem;
  font-size: 1rem;
  color: #FFFFFF;
}
.component-order-step.payment-summary .component-order-step-inner p img {
  display: inline-block;
}
.component-order-step.payment-summary .component-order-step-inner p:last-of-type {
  margin-bottom: 3rem;
}
.component-order-step.payment-summary .component-order-step-inner .choose-option-container {
  margin-bottom: 1rem;
}
.component-order-step.payment-summary .component-order-step-inner h5 {
  font-family: Gilroy-semibold, sans-serif;
}
.component-order-step.payment-summary .payment-failure-container h1 {
  color: #ea868f;
}
.component-order-step.payment-summary .payment-success-container h1 {
  color: #75b798;
}/*# sourceMappingURL=Payment.css.map */