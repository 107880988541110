button,
a,
label,
input {

    &.button {
        display: inline-block;
        padding: 0.8rem 2.1rem;
        font-family: Gilroy-semibold, sans-serif;
        text-decoration: none;
        border-radius: 7px;
        font-size: calc(1.4rem - 2px);
        text-align: center;
        opacity: 1;
        border: 1px solid transparent;

        &.status-of-service {
            padding: 0.7rem;
            border-radius: 7px;
            min-width: 7rem;
            text-align: center;
            font-size: 1rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
            cursor: pointer;

            &:hover {
                @media (min-width: 768px) {
                    color: rgba(0, 224, 255, 1);
                }
            }

            &.prepare {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgba(255, 255, 255, 1);
                border: 1px solid rgba(0, 224, 255, 1);

                &:hover {
                    @media (min-width: 768px) {
                        color: rgba(0, 224, 255, 1);
                    }

                }
            }

            &.awaiting {
                background-color: rgba(0, 0, 0, 0.2);
                color: #ffff00;
                border: 1px solid #ffff00;
                cursor: default;
            }

            &.completed {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgb(255, 1, 128);
                border: 1px solid rgb(255, 1, 128);
                cursor: default;
            }

            &.ready {
                background-color: rgba(0, 0, 0, 0.2);
                color: #00ff00;
                border: 1px solid #00ff00;
                cursor: default;
            }

            @media (max-width: 991px) {
                font-size: 0.9rem;
            }
        }

        &.status-of-invoice-sent {
            padding: 0.7rem;
            border-radius: 7px;
            min-width: 7rem;
            text-align: center;
            font-size: 1rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
            cursor: pointer;

            &.false {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgba(255, 255, 255, 1);
                border: 1px solid rgba(0, 224, 255, 1);

                &:hover {
                    @media (min-width: 768px) {
                        color: rgba(0, 224, 255, 1);
                    }
                }
            }

            &.true {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgb(255, 1, 128);
                border: 1px solid rgb(255, 1, 128);
                cursor: default;
            }

            @media (max-width: 991px) {
                padding: 0.4rem 0.2rem;
                min-width: 5rem;
            }
        }

        &.try {
            background-color: rgba(0, 224, 255, 1);
            color: black;
            transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;

            &.black {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgba(0, 224, 255, 1);
                border: 1px solid rgba(0, 224, 255, 0.5);

                &:hover {
                    @media (min-width: 768px) {
                        border: 1px solid rgba(198, 196, 196, 1);
                        color: rgb(255, 255, 255);
                    }
                }
            }

            &.gray {
                background-color: rgba(0, 0, 0, 0.2);
                color: rgba(198, 196, 196, 1);
                border: 1px solid rgba(198, 196, 196, 1);

                &:hover {
                    @media (min-width: 768px) {
                        border: 1px solid rgb(255, 255, 255);
                        color: rgb(255, 255, 255);
                    }
                }
            }

            &:hover {
                @media (min-width: 768px) {
                    background-color: rgba(0, 0, 0, 0.2);
                    color: rgba(0, 224, 255, 1);
                    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
                    border: 1px solid rgba(0, 224, 255, 1);
                }
            }

            &.hover2 {
                background-color: rgba(0, 224, 255, 1);
                color: black;

                &:disabled {
                    background-color: rgb(150, 151, 151);
                    border-color: rgb(150, 151, 151);
                    cursor: default;
                    pointer-events: none;
                }

                &:hover {
                    @media (min-width: 768px) {
                        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
                        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
                    }
                }

            }

            &.nohover {
                &:hover {
                    @media (min-width: 768px) {
                        background-color: rgba(0, 224, 255, 1);
                        color: black;
                    }
                }
            }
        }

        &.small {
            padding: 0.6rem 1rem;
            font-size: 0.9rem;
            line-height: 1rem;
        }

        &.small-med {
            padding: 0.7rem 1.1rem;
            font-size: 1rem;
            line-height: 1rem;
        }

        &.medium {
            padding: 0.8rem 2rem;
            font-size: 1.2rem;
            line-height: 1.3rem;
        }

        &.radio,
        &.checkbox {
            padding: 0.8rem 1.7rem;
            font-size: 1rem;
            min-width: 13rem;
            border: 2px solid rgb(0, 224, 255);

            &:hover {
                @media (min-width: 768px) {
                    border: 2px solid rgb(0, 224, 255);
                }
            }
        }

        &.simple {

            padding: 0.6rem 0.6rem;
            font-size: 1rem;
            background-color: rgba(0, 224, 255, 1);
            color: black;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);


            &:hover {
                @media (min-width: 768px) {
                    background-color: rgba(0, 224, 255, 1);
                    color: black;
                }
            }

            @media (max-width: 991px) {
                font-size: 0.9rem;
            }
        }

        &:disabled {
            background-color: rgb(150, 151, 151);
            border-color: rgb(150, 151, 151);
            cursor: default;
            pointer-events: none;

            &:hover {
                @media (min-width: 768px) {
                    background-color: rgb(150, 151, 151);
                    border-color: rgb(150, 151, 151);
                    color: rgb(0, 0, 0)
                }
            }
        }

        &.disabled {
            background-color: rgb(150, 151, 151);
            border-color: rgb(150, 151, 151);
            cursor: default;
            pointer-events: none;

            &:hover {
                @media (min-width: 768px) {
                    background-color: rgb(150, 151, 151);
                    border-color: rgb(150, 151, 151);
                    color: rgb(0, 0, 0)
                }
            }
        }

        @media (max-width: 575px) {
            font-size: calc(1rem);
            padding: calc(0.8rem * 0.7) calc(2.1rem * 0.7);

            &.medium {
                font-size: 0.9rem;
            }
        }

        @media (min-width: 576px) and (max-width: 767px) {
            font-size: calc(1rem);
            padding: calc(0.8rem * 0.8) calc(2.1rem * 0.8);

            &.medium {
                font-size: 1rem;
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: calc(1.1rem);
        }

    }
}