.component-order-step {
    display: block;
    position: relative;
    min-height: 25rem;
    width: 100%;
    border: 1px solid rgba(0, 224, 255, 1);
    border-radius: 0.6rem;
    padding: 2rem 1rem;
    padding-bottom: 1rem;
    background-color: rgb(19, 19, 19, 0.7);
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: normal;
        min-height: inherit;

        .form.new-order-client {
            input {
                margin-bottom: 0 !important;
            }

            .form-group {
                margin-bottom: 1.5rem;
            }
        }


        h1 {
            color: #FFFFFF;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            transition: all 0.3s ease-in-out;
            flex: 0.1;
            margin-bottom: 0.6rem;
        }

        h3 {
            color: rgb(150, 151, 151);
            text-align: center;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.5px;
            font-family: Gilroy-regular, sans-serif;
            transition: all 0.3s ease-in-out;
            margin-bottom: 3rem;
            flex: 0.1;
        }

        h4 {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 2rem;
            line-height: 2.2rem;
            transition: all 0.3s ease-in-out;
            flex: 0.2;
        }

        h5 {
            color: rgb(150, 151, 151);
            font-size: 0.7rem;
            text-align: center;
            line-height: 1.5rem;
            letter-spacing: 0.5px;
            font-family: Gilroy-regular, sans-serif;
            transition: all 0.3s ease-in-out;
            margin-bottom: 0;
            flex: 0.1;
        }

        .content-container {
            flex: 5;
        }

    }

    &.flex-start {
        .component-order-step-inner {
            justify-content: flex-start;
        }
    }

    @media (max-width: 991px) {
        margin-bottom: 2rem;
    }

    @media (min-width: 992px) {
        height: 100%;
    }


    &[disabled] {
        opacity: 0.3;

        * {
            cursor: default;
            pointer-events: none;
        }
    }



    .form-label {
        font-size: 1rem;
        font-family: Gilroy-semibold, sans-serif;
        color: rgba(0, 224, 255, 1);
        text-align: left;
        margin-bottom: 0.5rem;

    }

    .form-userdata-group,
    .form-clientdata-group {
        margin-bottom: 0.7rem;

        &.alternative {

            .form-label {
                font-family: Gilroy-regular, sans-serif;
                color: rgba(0, 224, 255, 0.8);
            }
        }
    }



    .plaintext {
        font-family: Gilroy-semibold, sans-serif;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 0.7);
        border: rgba(0, 0, 0, 0.7);
    }

    .form-control {
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

        &:disabled {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }

}