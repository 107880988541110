.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }


  .prev-button {
      z-index: 1;
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      left: -4rem;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      overflow: hidden;

      &::before {
        position: absolute;
        transform: translate(0, 0);
        height: 100%;
        width: 100%;
        font-size: 1rem;
        z-index: 1;
        content: "";
        background: url("../../assets/img/icons/arrow_back.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-clip: border-box;
      }
  }

  .next-button {

    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    right: -4rem;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    transform: translate(0, -50%);
    border-radius: 50%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    overflow: hidden;

    &::before {
      position: absolute;
      transform: translate(0, 0);
      height: 100%;
      width: 100%;
      font-size: 1rem;

      z-index: 1;
      content: "";
      background: url("../../assets/img/icons/arrow_forward.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-clip: border-box;
    
      
    }
  }