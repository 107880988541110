.component-contact-form {
  position: relative;
  display: block;
}
.component-contact-form-inner h1 {
  font-size: 3rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3.1rem;
}
.component-contact-form-inner .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.component-contact-form-inner .button {
  margin-top: 2rem;
  font-size: 1rem;
  padding: 0.6rem 2.1rem;
  font-family: Gilroy-semibold, sans-serif;
}
.component-contact-form-inner .form-contact-group {
  margin-bottom: 0.7rem;
}
.component-contact-form-inner .form-contact-group.alternative .form-label {
  font-family: Gilroy-regular, sans-serif;
  color: rgba(0, 224, 255, 0.8);
}
.component-contact-form-inner .form-contact-group.alternative .form-control {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
}
.component-contact-form-inner .plaintext {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);
  border: rgba(0, 0, 0, 0.7);
}
.component-contact-form-inner .form-control:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}
.component-contact-form-inner .form-control.loading {
  color: transparent;
}
.component-contact-form-inner .form-control.loading::-moz-placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}
.component-contact-form-inner .form-control.loading::placeholder {
  color: transparent;
  background: linear-gradient(110deg, rgba(19, 18, 18, 0.5) 8%, rgba(0, 24, 41, 0.5) 18%, rgba(19, 18, 18, 0.5));
  border-radius: 7px;
  background-size: 200% 100%;
  animation: 0.5s loading linear infinite;
}/*# sourceMappingURL=ContactForm.css.map */