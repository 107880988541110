.section-try-now-pro {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 10rem;

    .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .try-now-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .button {
            margin: 1rem;

            @media (max-width: 575px) {
                margin: 0rem 0.2rem 0rem 0rem;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }

    .container {
        overflow: visible;
    }

    .monitor-cointainer {
        overflow: visible;
    }

    .partners-text-wrapper {
        margin-top: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;

        h4 {
            background: linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            font-family: Gilroy-regular, 'sans-serif';
        }
    }

    .partners-logos-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .logo-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8rem;
            height: 8rem;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            &.big {
                width: 14rem;
            }
            
            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }


}