.settings-button {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    padding: 0;
    width: 2.3rem;

    img {
        max-width: 100%;
    }

    &.btn.btn-primary {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        padding: 0;
        width: 2.3rem;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
        }

        &:active {
            background-color: transparent;
        }
    }

    &:hover {
        background-color: transparent;
        filter: invert(49%) sepia(71%) saturate(3535%) hue-rotate(163deg) brightness(97%) contrast(106%);
    }

    &:focus {
        background-color: transparent;
    }

    &:active {
        background-color: transparent;
    }
}