.component-sponsor-slider {
  display: block;
  position: relative;
}
.component-sponsor-slider-inner .each-slide-effect {
  height: 2rem;
}
.component-sponsor-slider-inner .each-slide-effect .image-wrapper {
  height: 100%;
  width: 100%;
}
.component-sponsor-slider-inner .each-slide-effect .image-wrapper img {
  max-height: 100%;
}
.component-sponsor-slider-inner .each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: auto;
  width: auto;
}
.component-sponsor-slider-inner .each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}/*# sourceMappingURL=SponsorSlider.css.map */