.product-box-description-container {
  background-color: rgb(19, 19, 19);
  border-radius: 1rem;
  border: 1px solid rgb(95, 92, 92);
  font-family: Gilroy-semibold, sans-serif;
  overflow: hidden;
  filter: drop-shadow(5px 7px 10px rgba(0, 224, 255, 0.1));
  flex-direction: row;
  display: flex;
}
@media (max-width: 575px) {
  .product-box-description-container {
    flex-direction: column;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .product-box-description-container {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box-description-container {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .product-box-description-container {
    flex-direction: row;
  }
}
.product-box-description-container .short-description-wrapper .time-and-mode-container {
  display: flex;
  flex-direction: row;
}
.product-box-description-container .short-description-wrapper h1 {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 2.1rem;
  width: 100%;
  text-align: left;
  color: #FFF;
  font-family: Gilroy-semibold, sans-serif;
  border-radius: 1rem;
  background-color: transparent;
  padding: 0 1.2rem 1.5rem 0;
  margin: 0;
  margin-inline-start: 0;
  letter-spacing: 1px;
}
.product-box-description-container .short-description-wrapper p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.1rem;
  width: 100%;
  text-align: left;
  color: rgb(0, 224, 255);
  font-family: Gilroy-semibold, sans-serif;
  border-radius: 1rem;
  background-color: transparent;
  padding: 0.2rem 0;
  padding-left: 0;
}
.product-box-description-container .list-container {
  margin-top: 3rem;
}
.product-box-description-container .shop-product-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 4rem;
  filter: drop-shadow(5px 5px 7px rgba(0, 224, 255, 0.1));
  flex: 0.5;
  width: 100%;
}
@media (max-width: 575px) {
  .product-box-description-container .shop-product-container {
    padding: 1rem 1rem;
  }
}
.product-box-description-container .description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 4rem;
  width: 100%;
  flex: 0.5;
}
@media (max-width: 575px) {
  .product-box-description-container .description-container {
    padding: 1rem 1rem;
  }
}
.product-box-description-container ol.product-box-ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: Gilroy-semibold, sans-serif;
  margin-top: 1rem;
  list-style-position: outside;
}
.product-box-description-container ol.product-box-ol li {
  list-style-type: none;
  counter-increment: item;
  list-style-position: outside;
  margin-bottom: 0.5rem;
  color: #FFF;
  font-family: Gilroy-regular;
  filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.1));
}
.product-box-description-container ol.product-box-ol li:before {
  content: counter(item);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.31rem;
  background-color: rgb(0, 224, 255);
  border: 1px solid rgb(0, 224, 255);
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 7px;
  width: 2rem;
  height: 2rem;
  font-family: Gilroy-bold, sans-serif;
}/*# sourceMappingURL=ProductBoxDescription.css.map */