.component-order-step {
  display: block;
  position: relative;
  min-height: 25rem;
  width: 100%;
  border: 1px solid rgb(0, 224, 255);
  border-radius: 0.6rem;
  padding: 2rem 1rem;
  padding-bottom: 1rem;
  background-color: rgba(19, 19, 19, 0.7);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.component-order-step-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
  min-height: inherit;
}
.component-order-step-inner .form.new-order-client input {
  margin-bottom: 0 !important;
}
.component-order-step-inner .form.new-order-client .form-group {
  margin-bottom: 1.5rem;
}
.component-order-step-inner h1 {
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  flex: 0.1;
  margin-bottom: 0.6rem;
}
.component-order-step-inner h3 {
  color: rgb(150, 151, 151);
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  transition: all 0.3s ease-in-out;
  margin-bottom: 3rem;
  flex: 0.1;
}
.component-order-step-inner h4 {
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 2rem;
  line-height: 2.2rem;
  transition: all 0.3s ease-in-out;
  flex: 0.2;
}
.component-order-step-inner h5 {
  color: rgb(150, 151, 151);
  font-size: 0.7rem;
  text-align: center;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  flex: 0.1;
}
.component-order-step-inner .content-container {
  flex: 5;
}
.component-order-step.flex-start .component-order-step-inner {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  .component-order-step {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .component-order-step {
    height: 100%;
  }
}
.component-order-step[disabled] {
  opacity: 0.3;
}
.component-order-step[disabled] * {
  cursor: default;
  pointer-events: none;
}
.component-order-step .form-label {
  font-size: 1rem;
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 224, 255);
  text-align: left;
  margin-bottom: 0.5rem;
}
.component-order-step .form-userdata-group,
.component-order-step .form-clientdata-group {
  margin-bottom: 0.7rem;
}
.component-order-step .form-userdata-group.alternative .form-label,
.component-order-step .form-clientdata-group.alternative .form-label {
  font-family: Gilroy-regular, sans-serif;
  color: rgba(0, 224, 255, 0.8);
}
.component-order-step .plaintext {
  font-family: Gilroy-semibold, sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.7);
  border: rgba(0, 0, 0, 0.7);
}
.component-order-step .form-control {
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
}
.component-order-step .form-control:disabled {
  background-color: rgba(255, 255, 255, 0.7);
}/*# sourceMappingURL=OrderStep.css.map */