@charset "UTF-8";
.component-statute-paragraph {
  display: block;
  position: relative;
  margin-bottom: 5rem;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
}
.component-statute-paragraph-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.component-statute-paragraph-inner h1 {
  font-size: 2rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-top: 4rem;
  margin-bottom: 4rem;
  align-self: center;
  text-align: center;
  font-family: Gilroy-semibold, sans-serif;
  display: inline-block;
}
.component-statute-paragraph-inner h1:first-of-type {
  margin-top: 0;
}
.component-statute-paragraph-inner p {
  background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgb(255, 255, 255) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  text-align: left;
}
.component-statute-paragraph-inner ol {
  counter-reset: item;
  list-style: none;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  padding-left: 0;
}
.component-statute-paragraph-inner ol ol {
  margin-left: 0;
  padding-left: 0;
}
.component-statute-paragraph-inner ol li {
  display: block;
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  text-align: left;
  background: linear-gradient(25deg, rgb(198, 196, 196) 70%, rgb(255, 255, 255) 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.component-statute-paragraph-inner ol li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
.component-statute-paragraph-inner ol li.headline {
  margin-top: 4rem;
  text-align: center;
}
.component-statute-paragraph-inner ol li.headline:first-of-type {
  margin-top: 0;
}
.component-statute-paragraph-inner ol li.headline::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-size: 2rem;
  background: linear-gradient(132deg, rgb(0, 224, 255) 100%, rgb(143, 241, 255) 120%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-family: Gilroy-semibold, sans-serif;
}
.component-statute-paragraph-inner ol .sub-list {
  counter-reset: sub-list;
  list-style: lower-alpha;
}
.component-statute-paragraph-inner ol .sub-list li {
  counter-reset: item;
  counter-increment: sub-list;
  list-style-type: lower-alpha;
}
.component-statute-paragraph-inner ol .sub-list li::before {
  content: "· ";
}
.component-statute-paragraph.modal-window {
  margin-top: 1.5rem;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner {
  -webkit-text-fill-color: inherit;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner h1 {
  font-size: 1.4rem;
  margin-bottom: 2.5rem;
  align-self: center;
  text-align: center;
  font-family: Gilroy-semibold, sans-serif;
  display: inline-block;
  background: none;
  color: #000000;
  -webkit-text-fill-color: inherit;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner h1:first-of-type {
  margin-top: 0;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner p {
  -webkit-text-fill-color: inherit;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  text-align: left;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol {
  counter-reset: item;
  list-style: none;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  padding-left: 0;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol ol {
  margin-left: 0;
  padding-left: 0;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol li {
  display: block;
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 0;
  letter-spacing: 0.5px;
  font-family: Gilroy-regular, sans-serif;
  margin-bottom: 1rem;
  text-align: left;
  -webkit-text-fill-color: inherit;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol li.headline {
  margin-top: 4rem;
  text-align: center;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol li.headline:first-of-type {
  margin-top: 0;
}
.component-statute-paragraph.modal-window .component-statute-paragraph-inner ol li.headline::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-size: 1.4rem;
  -webkit-text-fill-color: inherit;
  font-family: Gilroy-semibold, sans-serif;
}/*# sourceMappingURL=StatuteParagraph.css.map */