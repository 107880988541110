.section-team {
  padding-top: 0;
  margin-top: -1rem;
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0;
}
.section-team .team-members {
  min-height: 15rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.section-team .team-img-container {
  margin-top: 2rem;
}
@media (max-width: 575px) {
  .section-team .member-wrapper {
    margin-top: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .section-team .member-wrapper {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-team .member-wrapper {
    margin-top: 2rem;
  }
}/*# sourceMappingURL=SectionTeam.css.map */