.view-orders-history-container {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    .view-orders-history-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .alert {
            width: 100%;
        }
        .component-history-order-bar {
            margin-bottom: 0.8rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-sceletor {
                margin-bottom: 0.8rem;
            }
        }

        h2 {
            margin-top: 5rem;
            font-size: 2.2rem;
            background: #ffffff;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-family: 'Gilroy-bold', sans-serif;
            margin-bottom: 1rem;
            text-align: center;

        }
    }
}