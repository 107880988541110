.component-personal-sceletor {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 5rem;
        align-items: center;
        width: 100%;
        height: 100%;

        @keyframes loading {
            to {
                background-position-x: -200%;
            }
        }

        @media (max-width: 991px) {
            padding-left: 0;
            justify-content: center;
        }

        .personal-info-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            p {
                font-size: 0.8rem;
                font-weight: 700;
                margin-bottom: 0rem;
                align-self: flex-start;
                line-height: 1rem;
                font-family: Gilroy-semibold, sans-serif;
                color: transparent;
                background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
                border-radius: 7px;
                background-size: 200% 100%;
                animation: 0.5s loading linear infinite;
            }

            h2 {
                display: inline-block;
                font-size: 2.5rem;
                font-weight: 700;
                margin: 0;
                padding: 0;
                line-height: 2.5rem;
                text-align: left;
                align-self: flex-start;
                font-family: Gilroy-bold, sans-serif;
                color: transparent;
                background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
                border-radius: 7px;
                background-size: 200% 100%;
                animation: 0.5s loading linear infinite;
                margin-bottom: 0.2rem;

                @media (max-width: 991px) {
                    font-size: 2rem;
                }
            }

            h3 {
                font-size: 1.2rem;
                font-weight: 700;
                margin: 0;
                align-self: flex-start;
                line-height: 1.8rem;
                font-family: Gilroy-semibold, sans-serif;
                filter: drop-shadow(2px 1px 1px rgb(0 0 0 / 0.2));
                color: transparent;
                background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
                border-radius: 7px;
                background-size: 200% 100%;
                animation: 0.5s loading linear infinite;

                @media (max-width: 991px) {
                    font-size: 1.1rem;
                }

            }
        }

        .personal-avatar-wrapper {

            height: 100%;
            width: 7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: right;
            margin-right: 2%;
            color: transparent;
            background: linear-gradient(110deg, rgba(19, 18, 18, 0.8) 8%, #001829 18%, rgba(19, 18, 18, 0.8));
            border-radius: 7px;
            background-size: 200% 100%;
            animation: 0.5s loading linear infinite;

            img {
                filter: drop-shadow(1px 4px 1px rgba(0, 0, 0, 1));
            }

            .circular--landscape {
                display: inline-block;
                position: relative;
                width: 125px;
                height: 125px;
                overflow: hidden;
                border-radius: 50%;
            }

            .circular--landscape img {
                width: auto;
                height: 100%;
                margin-left: -50px;
            }

            @media (max-width: 991px) {
                width: 6rem;
            }
        }


    }
}