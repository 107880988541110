.component-account-menu {
  display: inline-block;
  position: relative;
  opacity: 0.8;
  border: 1px solid #00e0ff;
  padding: 0.5rem 1.2rem;
  height: 2.71rem;
  border-radius: 7px;
}
.component-account-menu-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.component-account-menu-inner p {
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  font-family: Gilroy-regular, sans-serif;
  line-height: 1.2rem;
  letter-spacing: normal;
}
@media (max-width: 1199px) {
  .component-account-menu-inner p {
    font-size: 1.05rem;
  }
}
.component-account-menu-inner img {
  display: inline-block;
  margin-right: 0.2rem;
  width: 1.9rem;
  opacity: 0.8;
}
.component-account-menu:hover {
  cursor: pointer;
  opacity: 1;
}
.component-account-menu:hover img {
  opacity: 1;
}/*# sourceMappingURL=AccountMenu.css.map */